import { useAsk } from '~/hooks/ask';

export const Response = () => {
  const { response } = useAsk();

  return (
    <div className="mx-auto mt-10 max-w-2xl px-4 text-lg leading-relaxed text-muted-foreground md:mt-16">
      {response}
    </div>
  );
};
