import { UniqueIdentifier } from '@dnd-kit/core';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  listBoardStages,
  updateBoardItem,
  updateBoardItemPositions,
  updateBoardStagePositions,
} from '~/api';
import { BoardItemsObject, BoardStagesObject } from '~/board';
import { BoardItem, BoardStageExpanded } from '~/db';
import { Items } from '~/dnd';
import { pusher } from '~/pusher';

interface BoardContextType {
  items: Items;
  setItems: React.Dispatch<React.SetStateAction<Items>>;
  containers: UniqueIdentifier[];
  setContainers: React.Dispatch<React.SetStateAction<UniqueIdentifier[]>>;
  saveStagePositions: (stageIds: UniqueIdentifier[]) => Promise<void>;
  saveItemPositions: (items: Items) => Promise<void>;
  getStage: (stageId: UniqueIdentifier) => BoardStageExpanded | null;
  getItem: (itemId: UniqueIdentifier) => BoardItem | null;
  selectedBoardItem: BoardItem | null;
  hasBoardItemSelected: boolean;
  selectBoardItem: (item: BoardItem) => void;
  selectBoardItemById: (itemId: UniqueIdentifier) => void;
  unselectBoardItem: () => void;
  isBoardItemSelected: (item: BoardItem) => boolean;
  hasBoardItems: boolean;
  updateSelectedBoardItem: (updateData: {
    title?: string;
    description?: string;
  }) => void;
}

export const BoardContext = createContext<BoardContextType | undefined>(
  undefined,
);

export const BoardProvider = ({
  boardId,
  children,
}: {
  boardId: string;
  children: React.ReactNode;
}) => {
  const [items, setItems] = useState<Items>({});
  const [containers, setContainers] = useState<UniqueIdentifier[]>([]);
  const [stagesObject, setStagesObject] = useState<BoardStagesObject>({});
  const [itemsObject, setItemsObject] = useState<BoardItemsObject>({});
  const [hasBoardItemSelected, setIsBoardItemSelected] = useState(false);
  const [selectedBoardItem, setSelectedBoardItem] = useState<BoardItem | null>(
    null,
  );
  const hasBoardItems = Object.keys(items).length > 0;

  const saveStagePositions = useCallback(
    async (stageIds: UniqueIdentifier[]) => {
      await updateBoardStagePositions({ boardId, stageIds });
    },
    [boardId],
  );

  const saveItemPositions = useCallback(
    async (items: Items) => {
      await updateBoardItemPositions({ boardId, items });
    },
    [boardId],
  );

  const loadStages = useCallback(async () => {
    const { stages } = await listBoardStages({ boardId });
    const items: Items = {};

    stages.forEach((stage) => {
      items[stage.id] = [];

      stage.boardItems.forEach((boardItem) => {
        items[stage.id].push(boardItem.id);
      });
    });

    const stagesObject: BoardStagesObject = {};

    stages.forEach((stage) => {
      stagesObject[stage.id] = stage;
    });

    const itemsObject: BoardItemsObject = {};

    stages.forEach((stage) => {
      stage.boardItems.forEach((item) => {
        itemsObject[item.id] = item;
      });
    });

    setItems(items);
    setStagesObject(stagesObject);
    setItemsObject(itemsObject);
    setContainers(Object.keys(items) as UniqueIdentifier[]);
  }, [boardId]);

  const getStage = useCallback(
    (stageId: UniqueIdentifier) => {
      const stage = stagesObject[stageId];

      if (!stage) return null;

      return stage;
    },
    [stagesObject],
  );

  const getItem = useCallback(
    (itemId: UniqueIdentifier) => {
      const item = itemsObject[itemId];

      if (!item) return null;

      return item;
    },
    [itemsObject],
  );

  const selectBoardItemById = useCallback(
    (itemId: UniqueIdentifier) => {
      const item = getItem(itemId);

      if (item) {
        selectBoardItem(item);
      }
    },
    [itemsObject, getItem],
  );

  const selectBoardItem = useCallback((boardItem: BoardItem) => {
    setSelectedBoardItem(boardItem);
    setIsBoardItemSelected(true);
  }, []);

  const unselectBoardItem = useCallback(() => {
    window.location.href = '#';
  }, []);

  const isBoardItemSelected = useCallback(
    (item: BoardItem) => {
      return selectedBoardItem?.id === item.id;
    },
    [selectedBoardItem],
  );

  const updateSelectedBoardItem = useCallback(
    async (updateData: { title?: string; description?: string }) => {
      if (!selectedBoardItem) return;

      const { item } = await updateBoardItem({
        boardItemId: selectedBoardItem.id,
        updateData,
      });

      setSelectedBoardItem(item);
      loadStages();
    },
    [selectedBoardItem, loadStages],
  );

  useEffect(() => {
    loadStages();
  }, [loadStages]);

  useEffect(() => {
    const channel = pusher.subscribe(boardId);

    channel.bind('update', () => {
      loadStages();
    });

    return () => {
      pusher.unsubscribe(boardId);
    };
  }, [boardId, loadStages]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);

      if (hash) {
        selectBoardItemById(hash);
      } else {
        setIsBoardItemSelected(false);
      }
    };

    // Call the handler initially to handle the current hash
    handleHashChange();

    // Add an event listener for hash changes
    window.addEventListener('hashchange', handleHashChange);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('hashchange', handleHashChange);
    };
  }, [selectBoardItemById]);

  return (
    <BoardContext.Provider
      value={{
        items,
        setItems,
        containers,
        setContainers,
        saveStagePositions,
        saveItemPositions,
        getStage,
        getItem,
        selectedBoardItem,
        hasBoardItemSelected,
        selectBoardItem,
        selectBoardItemById,
        unselectBoardItem,
        isBoardItemSelected,
        hasBoardItems,
        updateSelectedBoardItem,
      }}
    >
      {children}
    </BoardContext.Provider>
  );
};

export const useBoard = () => {
  const context = useContext(BoardContext);

  if (!context) {
    throw new Error('useBoard must be used within a BoardProvider');
  }

  return context;
};
