import { useQuery } from '@tanstack/react-query';
import { listBoards } from '~/api';
import { ActionButton, Link, Separator } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';

export const BoardsPage = () => {
  const copy = useCopy();

  const { data: boards } = useQuery({
    queryKey: ['boards'],
    queryFn: async () => {
      const { boards } = await listBoards({});

      return boards;
    },
  });

  if (!boards) return null;

  return (
    <DashboardPage title={copy.get('boards')}>
      <div className="mx-auto max-w-4xl space-y-6">
        <h1 className="pb-6 text-lg font-semibold lg:text-2xl">
          {copy.get('boards')}
        </h1>

        {boards.length > 0 ? (
          <div className="overflow-hidden rounded-xl border dark:border-none dark:bg-muted/50">
            {boards.map((board, boardIndex) => {
              return (
                <div className="hover:bg-muted/40" key={board.id}>
                  {boardIndex !== 0 && <Separator className="opacity-90" />}

                  <Link href={`/boards/${board.id}`}>
                    <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-8 xl:py-10">
                      <div className="font-semibold">{board.name}</div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-balance rounded-lg border px-6 py-20 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noBoards')}
          </div>
        )}
      </div>

      <ActionButton href="/boards/new" />
    </DashboardPage>
  );
};
