import { UniqueIdentifier } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { BoardItemCard } from '~/components/board';

interface SortableItemProps {
  containerId: UniqueIdentifier;
  id: UniqueIdentifier;
  index: number;
  disabled?: boolean;
  getIndex(id: UniqueIdentifier): number;
}

export function SortableBoardItemCard({
  disabled,
  id,
  index,
}: SortableItemProps) {
  const {
    setNodeRef,
    setActivatorNodeRef,
    listeners,
    isDragging,
    isSorting,
    transform,
    transition,
  } = useSortable({
    id,
  });

  return (
    <BoardItemCard
      ref={disabled ? undefined : setNodeRef}
      boardItemId={id}
      dragging={isDragging}
      sorting={isSorting}
      handleProps={{ ref: setActivatorNodeRef }}
      index={index}
      transition={transition}
      transform={transform}
      listeners={listeners}
      handle
    />
  );
}
