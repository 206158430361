import { useMutation } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { analytics } from '~/analytics';
import { deleteBoardItem } from '~/api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  BasicButton,
  OriginalButton,
} from '~/components';
import { BoardItem } from '~/db';
import { useBoard } from '~/hooks/board';
import { useCopy } from '~/hooks/copy';
import { sentry } from '~/sentry';

export const BoardItemDetail = () => {
  const { selectedBoardItem, hasBoardItemSelected, unselectBoardItem } =
    useBoard();
  const show = hasBoardItemSelected && selectedBoardItem;

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        unselectBoardItem();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <AnimatePresence>
      {show && (
        <div className="fixed left-0 top-0 z-30">
          <motion.div
            className="fixed bottom-0 left-0 right-0 top-0 bg-background/80"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={unselectBoardItem}
          />

          <div className="fixed bottom-0 top-16 w-full max-w-full md:left-1/2 md:top-1/2 md:h-[80vh] md:max-h-[50rem] md:w-full md:max-w-4xl md:-translate-x-1/2 md:-translate-y-1/2">
            <div className="h-full p-5 md:p-8">
              <motion.div
                className="h-full w-full overflow-auto rounded-xl bg-background drop-shadow-2xl"
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.8, opacity: 0 }}
              >
                <div className="h-full w-full bg-muted/80">
                  <motion.div
                    className="flex h-full flex-col"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: {
                        delay: 0.2,
                      },
                    }}
                    exit={{ opacity: 0 }}
                  >
                    <Header selectedBoardItem={selectedBoardItem} />

                    <Content selectedBoardItem={selectedBoardItem} />

                    <Footer selectedBoardItem={selectedBoardItem} />
                  </motion.div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      )}
    </AnimatePresence>
  );
};

const Header = ({ selectedBoardItem }: { selectedBoardItem: BoardItem }) => {
  const { updateSelectedBoardItem } = useBoard();
  const copy = useCopy();
  const [title, setTitle] = useState(selectedBoardItem.title || '');

  useEffect(() => {
    if (!selectedBoardItem.title) {
      setTimeout(() => {
        document.getElementById('board-item-detail-title')?.focus();
      }, 100);
    }
  }, []);

  return (
    <div className="flex items-center border-b border-white/5 px-6 py-1 md:py-3 lg:py-6">
      <input
        className="w-full bg-transparent py-4 text-base outline-none placeholder:text-muted-foreground/50 disabled:cursor-not-allowed disabled:opacity-50 md:text-xl lg:text-2xl"
        id="board-item-detail-title"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        placeholder={copy.get('title')}
        tabIndex={1}
        autoCapitalize="on"
        autoCorrect="on"
        spellCheck
        onBlur={() => {
          if (title !== selectedBoardItem.title) {
            updateSelectedBoardItem({ title });
          }
        }}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            event.currentTarget.blur();
          }
        }}
      />

      <CloseButton />
    </div>
  );
};

const Content = ({ selectedBoardItem }: { selectedBoardItem: BoardItem }) => {
  const { updateSelectedBoardItem } = useBoard();
  const [description, setDescription] = useState(
    selectedBoardItem.description || '',
  );
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  // Function to adjust the height of the textarea
  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'; // Reset the height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`; // Set it to the scroll height
    }
  };

  useEffect(() => {
    adjustTextareaHeight(); // Adjust height on initial render
  }, [description]);

  return (
    <div className="hide-scrollbar relative flex-1 overflow-auto p-6">
      <textarea
        className="hide-scrollbar min-h-[50%] w-full resize-none bg-transparent pb-40 text-muted-foreground outline-none placeholder:text-muted-foreground/50 md:text-lg md:leading-relaxed"
        ref={textareaRef}
        value={description}
        tabIndex={2}
        onChange={(event) => {
          setDescription(event.target.value);
          adjustTextareaHeight();
        }}
        onBlur={() => updateSelectedBoardItem({ description })}
        placeholder="Description..."
      />
    </div>
  );
};

const Footer = ({ selectedBoardItem }: { selectedBoardItem: BoardItem }) => {
  return (
    <div className="flex items-center border-t border-white/5 px-6 py-3 md:py-4 lg:py-6">
      <div className="ml-auto">
        <DeleteButton selectedBoardItem={selectedBoardItem} />
      </div>
    </div>
  );
};

const CloseButton = () => {
  const { unselectBoardItem } = useBoard();

  return (
    <motion.button
      className="-mr-4 ml-auto cursor-pointer p-3 text-muted-foreground md:-mr-2"
      initial={{ opacity: 0.7 }}
      whileHover={{ scale: 1.1, opacity: 1 }}
      whileTap={{ scale: 0.95 }}
      onClick={unselectBoardItem}
      tabIndex={3}
    >
      <svg
        className="size-7"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        />
      </svg>
    </motion.button>
  );
};

const DeleteButton = ({
  selectedBoardItem,
}: {
  selectedBoardItem: BoardItem;
}) => {
  const { unselectBoardItem } = useBoard();
  const copy = useCopy();
  const remove = useMutation({
    mutationFn: async () => {
      await deleteBoardItem({ boardItemId: selectedBoardItem.id });
    },
    onSuccess: async () => {
      analytics.track('board.item.delete');
      unselectBoardItem();
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <BasicButton
          className="bg-transparent"
          variant="outline"
          size="sm"
          disabled={remove.isPending}
        >
          {remove.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          )}
        </BasicButton>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{copy.get('areYouSure')}</AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>

          <AlertDialogAction asChild>
            <OriginalButton
              onClick={() => {
                remove.mutate();
              }}
            >
              {copy.get('delete')}
            </OriginalButton>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
