import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { deletePassword, getPassword, updatePassword } from '~/api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  BasicButton,
  Form,
  FormInput,
  FormPasswordInput,
  FormSubmitButton,
  FormTextarea,
  OriginalButton,
} from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { Password } from '~/db';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { getOrgId } from '~/org';
import { decodePassword } from '~/password';
import { sentry } from '~/sentry';

export const EditPasswordPage = () => {
  const { passwordId } = useParams<{ passwordId: string }>();
  const [password, setPassword] = useState<Password | null>(null);

  useEffect(() => {
    const fn = async () => {
      const orgId = getOrgId();
      const { password } = await getPassword({ orgId, passwordId });

      setPassword(password);
    };

    fn();
  }, [passwordId]);

  if (!password) return null;

  return <EditPassword password={password} />;
};

export const EditPassword = ({ password }: { password: Password }) => {
  const copy = useCopy();
  const [name, setName] = useState(password.name);
  const [description, setDescription] = useState(password.description || '');
  const [passwordText, setPasswordText] = useState(
    decodePassword(password.password),
  );
  const navigate = useNavigate();

  const update = useMutation({
    mutationFn: async ({
      name,
      passwordText,
      description,
    }: {
      name: string;
      passwordText: string;
      description: string;
    }) => {
      await updatePassword({
        orgId: password.orgId,
        passwordId: password.id,
        name,
        passwordText,
        description,
      });
    },
    onSuccess: async () => {
      analytics.track('password.update');

      navigate('/passwords');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <DashboardPage
      headerTitle={copy.get('editPassword')}
      back="/passwords"
      headerOptions={
        <>
          <DeletePasswordButton password={password} />
        </>
      }
    >
      <Form
        onSubmit={() => {
          if (!name || !passwordText) return;

          update.mutate({
            name,
            passwordText,
            description,
          });
        }}
      >
        <FormInput value={name} onChange={setName} label="passwordName" />

        <FormTextarea
          value={description}
          onChange={setDescription}
          label="description"
          optional
        />

        <FormPasswordInput
          value={passwordText}
          onChange={setPasswordText}
          label="password"
        />

        <FormSubmitButton isLoading={update.isPending} label="save" />
      </Form>
    </DashboardPage>
  );
};

const DeletePasswordButton = ({ password }: { password: Password }) => {
  const copy = useCopy();
  const navigate = useNavigate();
  const remove = useMutation({
    mutationFn: async () => {
      await deletePassword({
        passwordId: password.id,
      });
    },
    onSuccess: async () => {
      analytics.track('password.delete');

      navigate('/passwords');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <BasicButton variant="outline" size="sm" disabled={remove.isPending}>
          {remove.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          )}
        </BasicButton>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{copy.get('areYouSure')}</AlertDialogTitle>

          <AlertDialogDescription className="pb-6">
            {copy.get('deletePassword')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>

          <AlertDialogAction asChild>
            <OriginalButton
              onClick={() => {
                remove.mutate();
              }}
            >
              {copy.get('delete')}
            </OriginalButton>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
