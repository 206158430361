import { BasicButton, Link } from '~/components';
import { useShare } from '~/hooks/share';
import { ShareResource } from '~/share';

export const ShareButton = ({
  resource,
  href,
}: {
  resource: ShareResource;
  href: string;
}) => {
  const { canShare } = useShare(resource);

  if (!canShare) return null;

  return (
    <Link href={href}>
      <BasicButton size="sm" variant="outline">
        <svg
          className="size-5"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
          />
        </svg>
      </BasicButton>
    </Link>
  );
};
