import { motion } from 'framer-motion';
import { Link } from '~/components';

export const DashboardPageHeader = ({
  title,
  back,
  options,
}: {
  title?: string;
  back?: string;
  options?: React.ReactNode;
}) => {
  return (
    <div className="flex h-20 items-center gap-4 pl-8 pr-4 md:pl-10 lg:h-28 lg:pl-12 lg:pr-7">
      {back && (
        <Link href={back}>
          <motion.div
            className="-ml-1 mt-1 p-2"
            initial={{ x: 0 }}
            whileHover={{
              x: -2,
            }}
          >
            <svg
              className="size-6 lg:size-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 15.75 3 12m0 0 3.75-3.75M3 12h18"
              />
            </svg>
          </motion.div>
        </Link>
      )}

      {title && (
        <div className="text-lg font-semibold lg:text-2xl">{title}</div>
      )}

      {options && <div className="ml-auto flex gap-4">{options}</div>}
    </div>
  );
};
