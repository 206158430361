import { z } from 'zod';

/////////////////////////////////////////
// CONTENT SCHEMA
/////////////////////////////////////////

export const ContentSchema = z.object({
  id: z.string(),
  created: z.number().int(),
  updated: z.number().int(),
  orgId: z.string(),
  userId: z.string(),
  date: z.number().int(),
  title: z.string(),
  text: z.string(),
  location: z.string().nullable(),
  summary: z.string().nullable(),
  isArchived: z.boolean(),
  autoGenerateText: z.boolean(),
  autoGenerateAudio: z.boolean(),
  textInstructions: z.string().nullable(),
})

export type Content = z.infer<typeof ContentSchema>

export default ContentSchema;
