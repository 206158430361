import { Pay } from '~/components/plan';
import { useOrg } from '~/hooks/org';

export const PlanGuard = ({ children }: { children: React.ReactNode }) => {
  const { org, isOwner, hasOrgPlan } = useOrg();

  if (!org) return null;

  if (!isOwner()) return children;

  if (!hasOrgPlan()) {
    return <Pay />;
  }

  return children;
};
