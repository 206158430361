import { useEffect } from 'react';
import { analytics } from '~/analytics';
import { useAuth } from '~/hooks/auth';

export const AnalyticsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { isLoaded, user } = useAuth();

  useEffect(() => {
    if (!isLoaded) return;

    if (user) {
      analytics.identify(user);
    } else {
      analytics.reset();
    }
  }, [isLoaded, user]);

  return <>{children}</>;
};
