import { ClientSideSuspense, RoomProvider } from '@liveblocks/react/suspense';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { getContent, onContentUpdate, updateContent } from '~/api';
import { SettingsButton } from '~/components';
import { ContentEditor } from '~/components/content';
import { DashboardPage } from '~/components/dashboard';
import { Content } from '~/db';
import { useParams } from '~/hooks';
import { useUser } from '~/hooks/auth';
import { useCopy } from '~/hooks/copy';
import { useLanguage } from '~/hooks/language';
import { invalidateQueries } from '~/query';
import { getRoomId } from '~/realtime';
import { sentry } from '~/sentry';

export const ContentPage = () => {
  const { contentId } = useParams<{ contentId: string }>();
  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ['content', contentId],
    queryFn: async () => {
      const { content, contentAudio } = await getContent({
        contentId,
      });

      if (!content) {
        navigate('/content');
      }

      return { content, contentAudio };
    },
  });

  const content = data?.content || null;

  if (!content) return null;

  return (
    <DashboardPage
      title={content.title}
      back={content.isArchived ? '/content/archive' : '/content'}
      mainClassName="pt-0 md:pt-0 lg:pt-0"
      headerOptions={
        <>
          <SettingsButton href={`/content/${content.id}/settings`} />
        </>
      }
    >
      <div className="mx-auto max-w-3xl space-y-4">
        <ContentTitle content={content} />

        <RoomProvider
          id={getRoomId({
            orgId: content.orgId,
            resourceType: 'content',
            resourceId: content.id,
          })}
        >
          <ClientSideSuspense
            fallback={
              <div className="pointer-events-none fixed left-1/2 top-1/2 h-10 w-10 -translate-x-1/2 -translate-y-1/2">
                <div className="loader" />
              </div>
            }
          >
            <ContentEditor content={content} />
          </ClientSideSuspense>
        </RoomProvider>
      </div>
    </DashboardPage>
  );
};

const ContentTitle = ({ content }: { content: Content }) => {
  const [title, setTitle] = useState(content.title || '');
  const { user } = useUser();
  const { language } = useLanguage();
  const copy = useCopy();

  const update = useMutation({
    mutationFn: async () => {
      await updateContent({
        contentId: content.id,
        data: {
          title,
        },
      });

      await onContentUpdate({
        userId: user.id,
        orgId: content.orgId,
        contentId: content.id,
        languageId: language.id,
      });
    },
    onSuccess: async () => {
      analytics.track('content.update');

      invalidateQueries(['content']);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <div>
      <input
        className="w-full bg-transparent py-6 text-2xl outline-none placeholder:text-muted-foreground/50 disabled:cursor-not-allowed disabled:opacity-50 sm:text-3xl md:text-4xl"
        id="board-item-detail-title"
        value={title}
        onChange={(event) => setTitle(event.target.value)}
        placeholder={copy.get('title')}
        tabIndex={1}
        autoCapitalize="on"
        autoCorrect="on"
        spellCheck
        onBlur={() => {
          if (!title) {
            setTitle(content.title);
          }

          if (title === content.title) return;

          update.mutate();
        }}
        onKeyUp={(event) => {
          if (event.key === 'Enter') {
            event.currentTarget.blur();
          }
        }}
      />
    </div>
  );
};
