import { useQuery } from '@tanstack/react-query';
import { listIncidents } from '~/api';
import { ActionButton, Link, Separator } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';
import { formatDateTime } from '~/utils';

export const IncidentsPage = () => {
  const copy = useCopy();

  const { data: incidents } = useQuery({
    queryKey: ['incidents'],
    queryFn: async () => {
      const { incidents } = await listIncidents({
        filter: {
          isResolved: false,
        },
      });

      return incidents;
    },
    refetchInterval: 5000,
  });

  if (!incidents) return null;

  return (
    <DashboardPage
      title={copy.get('incidents')}
      // headerOptions={
      //   <>
      //     <Link href="/incidents/resolved">
      //       <Button size="sm" variant="outline" label="resolved" />
      //     </Link>
      //   </>
      // }
    >
      <div className="mx-auto w-full max-w-4xl space-y-6">
        <h1 className="pb-6 text-lg font-semibold lg:text-2xl">
          {copy.get('incidents')}
        </h1>

        {incidents.length > 0 ? (
          <div className="mx-auto w-full max-w-4xl overflow-hidden rounded-lg border">
            {incidents.map((incident, incidentIndex) => {
              return (
                <div className="hover:bg-muted/40" key={incident.id}>
                  {incidentIndex !== 0 && <Separator />}

                  <Link href={`/incidents/${incident.id}`}>
                    <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-8 xl:py-10">
                      <div className="font-semibold">{incident.title}</div>

                      <div className="whitespace-nowrap text-muted-foreground lg:ml-auto">
                        {formatDateTime(incident.date, 'D MMM YY')}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="text-balance rounded-lg border px-6 py-40 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noIncidents')}
          </div>
        )}
      </div>

      <ActionButton href="/incidents/new" />
    </DashboardPage>
  );
};
