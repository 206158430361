import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { loadOrg } from '~/api';
import { Org, OrgMember } from '~/db';
import { getOrgId, removeOrgCookie, setOrgCookie } from '~/org';

const initialState = {
  isReady: false,
  orgId: getOrgId() as string | null,
  orgs: [] as Org[],
  org: null as Org | null,
  orgMember: null as OrgMember | null,
};

export const orgStore = create(
  combine(initialState, (set, get) => ({
    setIsReady: (isReady: boolean) => {
      set(() => ({ isReady }));
    },

    setOrg: (org: Org | null) => {
      setOrgCookie(org?.id);

      set(() => ({ org, orgId: org?.id }));
    },

    setOrgs: (orgs: Org[]) => {
      set(() => ({ orgs }));
    },

    isOwner: () => get().orgMember?.role === 'owner',

    loadFirstOrg: async () => {
      const { org, orgMember } = await loadOrg({});

      setOrgCookie(org?.id);
      set(() => ({ org, orgId: org?.id, orgMember }));
    },

    loadOrg: async (id?: string | null | undefined) => {
      try {
        const orgId = id || get().orgId;

        if (!orgId) return;

        const { org, orgMember } = await loadOrg({ orgId });

        if (!org || !orgMember) {
          removeOrgCookie();
        }

        set(() => ({ org, orgMember }));
      } catch (error) {
        set(() => ({ org: null, orgMember: null }));
      }
    },

    hasOrg: () => !!get().org,

    hasOrgPlan: () => !!get().org?.planId,
  })),
);

export function useOrg() {
  const store = orgStore();

  return { ...store };
}
