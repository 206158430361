import { Switch } from '~/components';
import { CopyKey } from '~/copy';
import { useCopy } from '~/hooks/copy';

export const FormSwitch = ({
  id,
  value,
  label,
  description,
  onChange,
}: {
  id: string;
  value: boolean;
  label?: CopyKey;
  description?: CopyKey;
  onChange: (value: boolean) => void;
}) => {
  const copy = useCopy();

  return (
    <div className="space-y-4">
      {label && <div>{copy.get(label)}</div>}

      <div className="rounded-xl border">
        <div className="flex items-center gap-4 p-5">
          <Switch id={id} checked={value} onCheckedChange={onChange} />

          {description && (
            <label
              className="leading-none text-muted-foreground peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
              htmlFor={id}
            >
              {copy.get(description)}
            </label>
          )}
        </div>
      </div>
    </div>
  );
};
