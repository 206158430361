import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Calendar as CalendarIcon, Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { createIncident, generateIncidentSummary } from '~/api';
import {
  BasicButton,
  Calendar,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Textarea,
} from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';
import { sentry } from '~/sentry';
import { cn } from '~/style';
import { dateToCurrentUnix } from '~/utils';

export const CreateIncidentPage = () => {
  const [date, setDate] = useState<Date>(new Date());
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const copy = useCopy();

  const create = useMutation({
    mutationFn: async (input: {
      description: string;
      location: string;
      date: number;
    }) => {
      const { incident } = await createIncident({
        ...input,
      });

      await generateIncidentSummary({
        orgId: incident.orgId,
        incidentId: incident.id,
      });

      return incident;
    },
    onSuccess: async (incident) => {
      analytics.track('incident.create');

      navigate(`/incidents/${incident.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <DashboardPage headerTitle={copy.get('reportIncident')} back="/incidents">
      <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
        <form
          className="space-y-6 md:space-y-10"
          onSubmit={(event) => {
            event.preventDefault();

            if (!description) return;

            create.mutate({
              description,
              location,
              date: dateToCurrentUnix(date),
            });
          }}
        >
          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">{copy.get('date')}</div>

            <Separator />

            <div className="p-6">
              <Popover>
                <PopoverTrigger asChild>
                  <BasicButton
                    variant={'outline'}
                    className={cn(
                      'w-full justify-start text-left font-normal',
                      !date && 'text-muted-foreground',
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />

                    {date ? (
                      dayjs(date).format('D MMM YYYY')
                    ) : (
                      <span>{copy.get('pickADate')}</span>
                    )}
                  </BasicButton>
                </PopoverTrigger>

                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={(event) => {
                      if (!(event instanceof Date)) return;

                      setDate(event);
                      document.getElementById('hidden')?.focus();
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>

          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('description')}
            </div>

            <Separator />

            <div className="p-6">
              <Textarea
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                rows={10}
              />
            </div>
          </div>

          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('location')}{' '}
              <span className="lowercase text-muted-foreground">
                ({copy.get('optional')})
              </span>
            </div>

            <Separator />

            <div className="p-6">
              <Textarea
                value={location}
                onChange={(event) => setLocation(event.target.value)}
                rows={4}
              />
            </div>
          </div>

          <BasicButton
            className="w-full"
            type="submit"
            disabled={create.isPending || !description}
          >
            {create.isPending ? (
              <Loader2 className="h-6 w-6 animate-spin" />
            ) : (
              copy.get('reportIncident')
            )}
          </BasicButton>
        </form>

        <input
          id="hidden"
          className="pointer-events-none fixed left-full top-full -z-50 h-0 w-0 opacity-0"
          type="text"
        />
      </div>
    </DashboardPage>
  );
};
