import { Link, Separator } from '~/components';
import { Content } from '~/db';
import { cn } from '~/style';
import { formatDateTime } from '~/utils';

export const Contents = ({
  contents,
  className,
}: {
  contents: Content[];
  className?: string;
}) => {
  return (
    <div
      className={cn(
        'mx-auto max-w-4xl overflow-hidden rounded-xl border dark:border-none dark:bg-muted/50',
        className,
      )}
    >
      {contents.map((content, contentIndex) => {
        return (
          <div className="hover:bg-muted/40" key={content.id}>
            {contentIndex !== 0 && <Separator className="opacity-90" />}

            <Link href={`/content/${content.id}`}>
              <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-8 xl:py-10">
                <div className="font-semibold">{content.title}</div>

                <div className="whitespace-nowrap text-muted-foreground lg:ml-auto">
                  {formatDateTime(content.updated, 'HH:mm - D MMM YYYY')}
                </div>
              </div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
