import { useEffect } from 'react';
import { pusher } from '~/pusher';
import { invalidateQueries } from '~/query';
import { useOrg } from '../../hooks/org';
import { getOrgId } from '../../org/cookie';

export const Realtime = () => {
  const { hasOrg } = useOrg();
  const hasActiveOrg = hasOrg();

  useEffect(() => {
    if (!hasActiveOrg) return;

    const orgId = getOrgId();

    if (!orgId) return;

    const channel = pusher.subscribe(orgId);

    channel.bind('content', () => {
      invalidateQueries(['content']);
    });

    channel.bind('incidents', () => {
      invalidateQueries(['incidents']);
    });

    channel.bind('members', () => {
      invalidateQueries(['members']);
    });

    channel.bind('meetings', () => {
      invalidateQueries(['meetings']);
    });

    return () => {
      pusher.unsubscribe(orgId);
    };
  }, [hasActiveOrg]);

  return null;
};
