import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { getMember, updateMember } from '~/api';
import { Badge, BasicButton, Input, Separator } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { User } from '~/db';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { usePlan } from '~/hooks/plan';
import { sentry } from '~/sentry';

export const EditMemberPage = () => {
  const { memberId } = useParams<{ memberId: string }>();
  const { data: member } = useQuery({
    queryKey: ['members', memberId],
    queryFn: async () => {
      const { member } = await getMember({ memberId });

      return member;
    },
  });

  if (!member) return null;

  return <EditMemberComponent member={member} />;
};

export const EditMemberComponent = ({ member }: { member: User }) => {
  const [name, setName] = useState(member.name);
  const [phone, setPhone] = useState(member.phone || '');
  const navigate = useNavigate();
  const copy = useCopy();
  const { hasEnterprise } = usePlan();

  const update = useMutation({
    mutationFn: async (input: { name: string; phone: string }) => {
      await updateMember({
        memberId: member.id,
        ...input,
      });
    },
    onSuccess: () => {
      analytics.track('member.update');

      navigate(`/members/${member.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <DashboardPage
      title={copy.get('editMember')}
      back={`/members/${member.id}`}
    >
      <div className="mx-auto w-full max-w-xl space-y-6 md:space-y-10">
        <div className="w-full space-y-2 rounded-lg border p-6">
          <div className="flex w-full items-center gap-4">
            <h1 className="text-2xl font-bold">{member.name}</h1>

            <div>
              <Badge variant="secondary">{copy.get('you')}</Badge>
            </div>
          </div>

          <div className="text-muted-foreground">{member.email}</div>
        </div>

        <form
          className="space-y-6 md:space-y-10"
          onSubmit={(event) => {
            event.preventDefault();

            update.mutate({
              name: name.trim(),
              phone: phone.trim(),
            });
          }}
        >
          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">{copy.get('name')}</div>

            <Separator />

            <div className="p-6">
              <Input
                value={name}
                onChange={(event) => setName(event.target.value)}
                placeholder={copy.get('name')}
              />
            </div>
          </div>

          {hasEnterprise() && (
            <div className="rounded-lg border">
              <div className="bg-muted/50 px-6 py-4">
                {copy.get('enterPhone')}
              </div>

              <Separator />

              <div className="p-6">
                <Input
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                  placeholder={copy.get('phone')}
                />
              </div>
            </div>
          )}

          <BasicButton
            className="w-full"
            type="submit"
            disabled={update.isPending}
          >
            {update.isPending ? (
              <Loader2 className="h-6 w-6 animate-spin" />
            ) : (
              copy.get('save')
            )}
          </BasicButton>
        </form>
      </div>
    </DashboardPage>
  );
};
