import { SignedIn, SignedOut } from '@clerk/clerk-react';
import { useEffect } from 'react';
import { BasicButton, Redirect, Separator } from '~/components';
import { CloudImage } from '~/components/cloudinary';
import { Header } from '~/components/home';
import { features } from '~/features';

export const HomePage = () => {
  useEffect(() => {
    document.title = 'Contential';
  }, []);

  return (
    <>
      <SignedIn>
        <Redirect />
      </SignedIn>

      <SignedOut>
        <>
          <Header />

          <main className="mx-auto max-w-7xl px-6 py-14 md:px-20 md:py-20 2xl:px-0">
            <div className="space-y-14 md:space-y-20 lg:space-y-28">
              <section className="relative mx-auto flex max-w-6xl px-8 text-center md:px-0 xl:items-center xl:gap-40">
                <div className="mx-auto max-w-sm space-y-8 md:max-w-xl md:space-y-14">
                  <h1 className="text-balance text-2xl font-bold leading-snug sm:text-3xl sm:leading-tight md:text-4xl md:leading-tight lg:text-5xl lg:leading-tight">
                    A platform for busy executives
                  </h1>

                  <div className="flex flex-col justify-center gap-4 md:flex-row md:gap-6">
                    <a href="/sign-up">
                      <BasicButton size="lg" className="mx-auto w-52 md:mx-0">
                        Get started
                      </BasicButton>
                    </a>
                  </div>
                </div>
              </section>

              <section>
                <CloudImage
                  className="rounded-xl"
                  id="board-room-1"
                  alt="Contential"
                />
              </section>

              <section className="relative mx-auto max-w-xl px-4 md:max-w-6xl md:px-0">
                <p className="mx-auto max-w-3xl py-4 text-lg md:py-10 md:text-2xl md:leading-relaxed lg:text-3xl lg:leading-snug xl:max-w-4xl xl:text-4xl xl:leading-snug">
                  Contential is a platform built for busy executives, tailored
                  to fit seamlessly into your workflow. It integrates with all
                  your essential services, consolidating data into one
                  convenient location. This allows you to stay focused on what
                  truly matters, driving results and achieving your goals.
                </p>
              </section>

              <section className="mx-auto max-w-5xl">
                <Separator />

                {features.map((feature) => (
                  <div key={feature.title}>
                    <div className="flex flex-col gap-4 pt-8 md:pt-10 lg:flex-row lg:items-center lg:space-y-0 lg:pt-14">
                      <div className="text-lg md:text-xl lg:text-2xl">
                        {feature.title}
                      </div>

                      <div className="max-w-lg text-muted-foreground md:text-lg lg:ml-auto lg:text-xl">
                        {feature.description}
                      </div>
                    </div>

                    <Separator className="mt-8 md:mt-10 lg:mt-14" />
                  </div>
                ))}
              </section>

              <div className="pt-10 md:pt-16">
                <Separator />
              </div>

              <section className="mx-auto max-w-6xl px-8 pb-10 pt-10 md:px-20 md:pb-20 lg:pb-32 xl:px-0">
                <div className="mx-auto max-w-lg">
                  <h2 className="mb-4 text-balance text-center text-2xl font-bold leading-normal md:text-3xl lg:text-4xl lg:leading-normal">
                    Ready to get started?
                  </h2>

                  <p className="text-balance text-center text-lg text-muted-foreground">
                    Sign up today to streamline your workflow and elevate team
                    productivity.
                  </p>
                </div>

                <div className="mt-16 text-center md:mt-16">
                  <a href="/sign-up">
                    <BasicButton
                      className="mx-auto w-40 md:mx-0 md:w-52"
                      size="lg"
                    >
                      Get started
                    </BasicButton>
                  </a>
                </div>
              </section>
            </div>
          </main>
        </>
      </SignedOut>
    </>
  );
};
