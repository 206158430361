import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { deleteBoard, getBoard, updateBoard, updateBoardStages } from '~/api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  BasicButton,
  Form,
  FormInput,
  FormSubmitButton,
  OriginalButton,
} from '~/components';
import { EditBoardStages } from '~/components/board';
import { DashboardPage } from '~/components/dashboard';
import { ShareButton } from '~/components/share';
import { Board, BoardStage } from '~/db';
import { useParams } from '~/hooks';
import { useBoardStages } from '~/hooks/board';
import { useCopy } from '~/hooks/copy';
import { invalidateQueries } from '~/query';
import { sentry } from '~/sentry';

export const BoardSettingsPage = () => {
  const { boardId } = useParams<{ boardId: string }>();
  const [board, setBoard] = useState<Board | null>(null);
  const { stages } = useBoardStages(boardId);

  useEffect(() => {
    const fn = async () => {
      const { board } = await getBoard({ boardId });

      setBoard(board);
    };

    fn();
  }, [boardId]);

  if (!board) return null;

  if (!stages) return null;

  return <EditBoard board={board} stages={stages} />;
};

export const EditBoard = ({
  board,
  stages,
}: {
  board: Board;
  stages: BoardStage[];
}) => {
  const [name, setName] = useState(board.name);
  const [items, setItems] = useState(
    stages.map((stage) => ({ id: stage.id, title: stage.title })),
  );
  const copy = useCopy();
  const navigate = useNavigate();

  const update = useMutation({
    mutationFn: async () => {
      await Promise.all([
        updateBoardStages({
          boardId: board.id,
          items,
        }),
        updateBoard({
          boardId: board.id,
          updateData: {
            name,
          },
        }),
      ]);
    },
    onSuccess: async () => {
      analytics.track('board.update');

      invalidateQueries(['boards']);

      navigate(`/boards/${board.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const submitUpdate = () => {
    if (!name) return;

    const hasTitles = items.every((item) => item.title);

    if (hasTitles) {
      update.mutate();
    }
  };

  return (
    <DashboardPage
      headerTitle={copy.get('settings')}
      back={`/boards/${board.id}`}
      headerOptions={
        <>
          <ShareButton resource={board} href={`/boards/${board.id}/share`} />

          <DeleteBoardButton board={board} />
        </>
      }
    >
      <div className="mx-auto w-full max-w-2xl space-y-6 md:space-y-10 lg:space-y-14">
        <Form onSubmit={submitUpdate}>
          <FormInput
            value={name}
            onChange={setName}
            label="boardName"
            placeholder="name"
          />
        </Form>

        <EditBoardStages items={items} setItems={setItems} />
      </div>

      <div className="fixed bottom-0 left-0 right-0 border-t bg-background p-4 md:py-6 lg:left-72">
        <div className="mx-auto w-40 md:w-52">
          <FormSubmitButton
            onClick={submitUpdate}
            isLoading={update.isPending}
            label="save"
          />
        </div>
      </div>
    </DashboardPage>
  );
};

const DeleteBoardButton = ({ board }: { board: Board }) => {
  const copy = useCopy();
  const navigate = useNavigate();
  const remove = useMutation({
    mutationFn: async () => {
      await deleteBoard({
        boardId: board.id,
      });
    },
    onSuccess: async () => {
      analytics.track('board.delete');

      navigate('/boards');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <BasicButton variant="outline" size="sm" disabled={remove.isPending}>
          {remove.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          )}
        </BasicButton>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{copy.get('areYouSure')}</AlertDialogTitle>

          <AlertDialogDescription className="pb-6">
            {copy.get('deleteBoard')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>

          <AlertDialogAction asChild>
            <OriginalButton
              onClick={() => {
                remove.mutate();
              }}
            >
              {copy.get('delete')}
            </OriginalButton>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
