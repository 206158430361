import { Input } from '~/components';
import { CopyKey } from '~/copy';
import { useCopy } from '~/hooks/copy';

export const FormInput = ({
  value,
  label,
  placeholder,
  onChange,
}: {
  value: string;
  label?: CopyKey;
  placeholder?: CopyKey;
  onChange: (value: string) => void;
}) => {
  const copy = useCopy();

  return (
    <div className="space-y-4">
      {label && <div>{copy.get(label)}</div>}

      <Input
        value={value}
        placeholder={placeholder && copy.get(placeholder)}
        onChange={(event) => onChange(event.target.value)}
      />
    </div>
  );
};
