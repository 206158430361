import { useQuery } from '@tanstack/react-query';
import { listIncidentAlerts } from '~/api';
import { Link, Separator } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { cn } from '~/style';
import { formatDateTime } from '~/utils';

export const IncidentAlertsPage = () => {
  const { incidentId } = useParams<{ incidentId: string }>();
  const copy = useCopy();

  const { data: alerts } = useQuery({
    queryKey: ['incidents', 'alerts'],
    queryFn: async () => {
      const { incidentAlerts } = await listIncidentAlerts({ incidentId });

      return incidentAlerts;
    },
  });

  if (!alerts) return null;

  return (
    <DashboardPage
      headerTitle={copy.get('alerts')}
      back={`/incidents/${incidentId}`}
    >
      <div className="grid gap-6">
        {alerts.length > 0 ? (
          <div className="mx-auto w-full max-w-4xl overflow-hidden rounded-lg border">
            {alerts.map((alert, alertIndex) => {
              return (
                <div className="hover:bg-muted/40" key={alert.id}>
                  {alertIndex !== 0 && <Separator />}

                  <Link href={`/incidents/${incidentId}/alerts/${alert.id}`}>
                    <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-6 xl:py-8">
                      <div>
                        {formatDateTime(alert.updated, 'HH:mm - D MMM YYYY')}
                      </div>

                      <div
                        className={cn(
                          'ml-auto inline-block rounded-full px-5 py-1 text-center text-sm font-semibold',
                          {
                            'bg-[rgb(43,172,110)] text-white':
                              alert.status === 'complete',
                            'bg-gray-600 text-white':
                              alert.status !== 'complete',
                          },
                        )}
                      >
                        {alert.status === 'complete'
                          ? copy.get('sent')
                          : copy.get('sending')}
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="mx-auto w-full max-w-4xl rounded-lg border py-40 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noAlertsSent')}
          </div>
        )}
      </div>
    </DashboardPage>
  );
};
