import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import {
  deleteContent,
  getContent,
  onContentUpdate,
  updateContent,
} from '~/api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  BasicButton,
  Form,
  FormSubmitButton,
  OriginalButton,
} from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { FormCheckbox } from '~/components/form/FormCheckbox';
import { ShareButton } from '~/components/share';
import { Content } from '~/db';
import { useParams } from '~/hooks';
import { useUser } from '~/hooks/auth';
import { useCopy } from '~/hooks/copy';
import { useLanguage } from '~/hooks/language';
import { invalidateQueries } from '~/query';
import { sentry } from '~/sentry';

export const ContentSettings = () => {
  const { contentId } = useParams<{ contentId: string }>();

  const { data } = useQuery({
    queryKey: ['content', contentId],
    queryFn: async () => {
      const { content } = await getContent({
        contentId,
      });

      return { content };
    },
  });
  const content = data?.content;

  if (!content) return null;

  return <Settings content={content} />;
};

const Settings = ({ content }: { content: Content }) => {
  const copy = useCopy();

  const [autoGenerateAudio, setAutoGenerateAudio] = useState(
    content.autoGenerateAudio,
  );

  const navigate = useNavigate();
  const { user } = useUser();
  const { language } = useLanguage();

  const update = useMutation({
    mutationFn: async () => {
      await updateContent({
        contentId: content.id,
        data: {
          autoGenerateAudio,
        },
      });

      await onContentUpdate({
        userId: user.id,
        orgId: content.orgId,
        contentId: content.id,
        languageId: language.id,
      });
    },
    onSuccess: async () => {
      analytics.track('content.update');

      invalidateQueries(['content']);
      navigate(`/content/${content.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <DashboardPage
      headerTitle={copy.get('settings')}
      back={`/content/${content.id}`}
      headerOptions={
        <>
          {content.isArchived && <DeleteContentButton content={content} />}

          <ArchiveContentButton content={content} />

          <ShareButton
            resource={content}
            href={`/content/${content.id}/share`}
          />
        </>
      }
    >
      <Form
        onSubmit={() => {
          update.mutate();
        }}
      >
        <FormCheckbox
          id="auto-generate-audio"
          label="audio"
          description="autoGenerateAudioDescription"
          value={autoGenerateAudio}
          onChange={setAutoGenerateAudio}
        />

        <FormSubmitButton isLoading={update.isPending} label="save" />
      </Form>
    </DashboardPage>
  );
};

const ArchiveContentButton = ({ content }: { content: Content }) => {
  const copy = useCopy();
  const { language } = useLanguage();
  const { user } = useUser();

  const archive = useMutation({
    mutationFn: async () => {
      const isArchived = !content?.isArchived;

      analytics.track(isArchived ? 'content.archive' : 'content.unarchive');

      await updateContent({
        contentId: content.id,
        data: {
          isArchived,
        },
      });

      await onContentUpdate({
        orgId: content.orgId,
        contentId: content.id,
        languageId: language.id,
        userId: user.id,
      });
    },
    onSuccess: () => {
      invalidateQueries(['content']);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <BasicButton variant="outline" size="sm" disabled={archive.isPending}>
          {archive.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : content.isArchived ? (
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
              />
            </svg>
          ) : (
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5M10 11.25h4M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
              />
            </svg>
          )}
        </BasicButton>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{copy.get('areYouSure')}</AlertDialogTitle>

          <AlertDialogDescription className="pb-6">
            {content.isArchived
              ? copy.get('actionUnarchiveContent')
              : copy.get('actionArchiveContent')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>

          <AlertDialogAction asChild>
            <OriginalButton
              onClick={() => {
                archive.mutate();
              }}
            >
              {content.isArchived ? copy.get('unarchive') : copy.get('archive')}
            </OriginalButton>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

const DeleteContentButton = ({ content }: { content: Content }) => {
  const copy = useCopy();
  const navigate = useNavigate();

  const remove = useMutation({
    mutationFn: async () => {
      await deleteContent({
        contentId: content.id,
      });
    },
    onSuccess: () => {
      analytics.track('content.delete');
      invalidateQueries(['content']);

      navigate('/content');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <BasicButton variant="outline" size="sm" disabled={remove.isPending}>
          {remove.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          )}
        </BasicButton>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{copy.get('areYouSure')}</AlertDialogTitle>

          <AlertDialogDescription className="pb-6">
            {copy.get('actionDeleteContent')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>{copy.get('cancel')}</AlertDialogCancel>

          <AlertDialogAction asChild>
            <OriginalButton
              onClick={() => {
                remove.mutate();
              }}
            >
              {copy.get('deleteContent')}
            </OriginalButton>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
