import { motion } from 'framer-motion';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from '~/components';
import { useSize } from '~/hooks';
import { useAuth } from '~/hooks/auth';
import { useCopy } from '~/hooks/copy';
import { useOrg } from '~/hooks/org';
import { cn } from '~/style';
import { getInitials } from '~/utils';

const nonDashboardNavRoutes = ['/', '/sign-in', '/sign-up', '/sign-out'];

export const DashboardNav = () => {
  const location = useLocation();
  const { isSignedIn } = useAuth();
  const { hasOrg, hasOrgPlan } = useOrg();

  const shouldShowNav = () => {
    let show = true;

    if (nonDashboardNavRoutes.includes(location.pathname)) {
      return false;
    }

    if (location.pathname.includes('/meetings/')) {
      return false;
    }

    return show;
  };

  const showNav = shouldShowNav();

  if (!isSignedIn) return null;

  if (!hasOrg()) return null;

  if (!hasOrgPlan()) return null;

  if (!showNav) return null;

  return <Nav />;
};

const Nav = () => {
  const location = useLocation();
  const [, id] = location.pathname.split('/');
  const copy = useCopy();
  const { user } = useAuth();
  const links = [
    {
      id: 'content',
      title: copy.get('contents'),
      href: '/content',
    },
    {
      id: 'boards',
      title: copy.get('boards'),
      href: '/boards',
    },
    {
      id: 'meetings',
      title: copy.get('meetings'),
      href: '/meetings',
    },
    {
      id: 'incidents',
      title: copy.get('incidents'),
      href: '/incidents',
    },
    {
      id: 'passwords',
      title: copy.get('passwords'),
      href: '/passwords',
    },
    {
      id: 'reminders',
      title: copy.get('reminders'),
      href: '/reminders',
    },
    {
      id: 'members',
      title: copy.get('members'),
      href: '/members',
    },
    {
      id: 'settings',
      title: copy.get('settings'),
      href: '/settings',
    },
  ];

  const currentIndex = links.findIndex((link) => link.id === id);
  const [left, setLeft] = useState(0);
  const [width, setWidth] = useState(0);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isLg } = useSize();
  const itemPadding = isLg ? 30 : 20;

  const setBar = useCallback(async () => {
    const element = document.getElementById(
      `dashboard-nav-item-${currentIndex}`,
    );

    if (element) {
      const left = element.offsetLeft;

      setLeft(left + itemPadding);
      setWidth(element.offsetWidth - itemPadding * 2);
      scrollToPosition(left - 50);
    }
  }, [currentIndex]);

  const scrollToPosition = (scrollPosition: number) => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({
        left: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    const handle = requestAnimationFrame(() => setBar());

    return () => cancelAnimationFrame(handle);
  }, [currentIndex, setBar]);

  return (
    <motion.div
      className="fixed left-0 top-0 z-30 h-16 w-full border-b bg-background/80 backdrop-blur-md md:h-20 lg:h-24"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <div className="h-full w-full overflow-x-auto overflow-y-hidden">
        <div
          className="h-[calc(100%+20px)] overflow-x-auto overflow-y-hidden"
          ref={scrollRef}
        >
          <div className="relative flex">
            <div className="w-3 shrink-0 md:w-5" />

            {links.map((link, linkIndex) => {
              const isActive = linkIndex === currentIndex;

              return (
                <Link href={link.href} key={link.id}>
                  <motion.div
                    className={cn(
                      'flex h-16 shrink-0 items-center justify-center whitespace-nowrap text-sm font-semibold text-muted-foreground/50 transition-colors md:h-20 lg:h-24 lg:text-[0.95rem]',
                      {
                        'text-primary': isActive,
                      },
                    )}
                    id={`dashboard-nav-item-${linkIndex}`}
                    style={{
                      paddingLeft: itemPadding,
                      paddingRight: itemPadding,
                    }}
                    whileTap={{ scale: 0.97 }}
                  >
                    {link.title}
                  </motion.div>
                </Link>
              );
            })}

            <div className="w-32 shrink-0" />

            <motion.div
              className="absolute top-0 -z-10 h-full"
              initial={{
                left: 0,
                width: 0,
                opacity: 0,
              }}
              animate={{
                left,
                width,
                opacity: 1,
                transition: {
                  type: 'spring',
                },
              }}
            >
              <div className="absolute left-0 top-1/2 -ml-4 h-10 w-[calc(100%+2rem)] -translate-y-1/2 rounded-full bg-muted lg:-ml-8 lg:h-12 lg:w-[calc(100%+4rem)]" />
            </motion.div>
          </div>
        </div>
      </div>

      <div className="pointer-events-none absolute right-0 top-0 z-20 h-16 w-28 border-b bg-gradient-to-r from-background/0 via-background via-50% to-background md:h-20 md:w-40 lg:h-24" />

      <div className="absolute right-0 top-0 z-30 flex h-16 w-16 items-center justify-center border-b md:right-4 md:h-20 lg:right-6 lg:h-24">
        <Link href="/settings">
          <div className="flex h-10 w-10 items-center justify-center rounded-full bg-muted/60 font-bold md:h-14 md:w-14">
            {user && getInitials(user.name)}
          </div>
        </Link>
      </div>
    </motion.div>
  );
};
