import { useEffect, useState } from 'react';
import { getIncidentAudioUrl } from '~/api';
import { AudioPlayer } from '~/components/audio';
import type { IncidentAudio } from '~/db';

export const IncidentAudioPlayer = ({
  incidentAudio,
}: {
  incidentAudio: IncidentAudio | null | undefined;
}) => {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    const fn = async () => {
      if (!incidentAudio || incidentAudio.status !== 'complete') {
        setUrl(null);

        return;
      }

      const { url } = await getIncidentAudioUrl({
        incidentAudioId: incidentAudio.id,
      });

      setUrl(url);
    };

    fn();
  }, [incidentAudio]);

  if (!url) return null;

  return <audio className="w-full" controls src={url} />;

  return <AudioPlayer className="px-6" src={url} />;
};
