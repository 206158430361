import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  OriginalButton,
} from '~/components';
import { CopyKey } from '~/copy';
import { useCopy } from '~/hooks/copy';

export const ConfirmButton = ({
  title,
  actionButtonLabel,
  description,
  onConfirmClick,
  className,
  children,
}: {
  title: CopyKey;
  actionButtonLabel: CopyKey;
  description?: CopyKey;
  onConfirmClick: () => void;
  className?: string;
  children?: React.ReactNode;
}) => {
  const copy = useCopy();

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <div className={className}>{children}</div>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{copy.get(title)}</AlertDialogTitle>

          {description && (
            <AlertDialogDescription className="pb-6">
              {copy.get(description)}
            </AlertDialogDescription>
          )}
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>

          <AlertDialogAction asChild>
            <OriginalButton onClick={onConfirmClick}>
              {copy.get(actionButtonLabel)}
            </OriginalButton>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
