import { ActionButton, EditButton, Separator } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { GroupExpanded } from '~/db';
import { useCopy } from '~/hooks/copy';
import { useGroups } from '~/hooks/group';

export const GroupsPage = () => {
  const copy = useCopy();
  const { groups } = useGroups();

  if (!groups) return null;

  return (
    <DashboardPage headerTitle={copy.get('groups')}>
      <div className="mx-auto w-full max-w-4xl space-y-6">
        {groups.length > 0 ? (
          <div className="mx-auto w-full max-w-4xl space-y-6 md:space-y-10 lg:space-y-14">
            {groups.map((group) => {
              return <GroupCard key={group.id} group={group} />;
            })}
          </div>
        ) : (
          <div className="text-balance rounded-lg border px-6 py-40 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noGroups')}
          </div>
        )}
      </div>

      <ActionButton href="/members/groups/new" />
    </DashboardPage>
  );
};

const GroupCard = ({ group }: { group: GroupExpanded }) => {
  const copy = useCopy();

  return (
    <div className="rounded-lg border">
      <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14">
        <div className="space-y-2">
          <div className="text-lg font-semibold md:text-xl">{group.name}</div>

          {group.groupMembers.length > 0 && (
            <div className="text-sm text-muted-foreground">
              {copy.get('members')} ({group.groupMembers.length})
            </div>
          )}
        </div>

        <div className="ml-auto flex gap-2 md:gap-3">
          <EditButton href={`/members/groups/${group.id}/edit`} />
        </div>
      </div>

      {group.groupMembers.length > 0 && (
        <div>
          <Separator />

          <div>
            {group.groupMembers.map((groupMember, groupMemberIndex) => (
              <div key={groupMember.id}>
                {groupMemberIndex > 0 && <Separator />}

                <div className="flex flex-col gap-4 px-8 py-4 md:gap-8 md:py-5 lg:flex-row lg:items-center">
                  <div className="flex items-center gap-4">
                    <div className="font-semibold lg:min-w-40">
                      {groupMember.member.name}
                    </div>
                  </div>

                  <div className="text-muted-foreground">
                    {groupMember.member.email}
                  </div>

                  {groupMember.member.phone && (
                    <div className="text-muted-foreground lg:ml-auto">
                      {groupMember.member.phone}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
