import { defineCopy } from '~/copy';
import action from '~/copy/action';
import ask from '~/copy/ask';
import auth from '~/copy/auth';
import board from '~/copy/board';
import content from '~/copy/content';
import group from '~/copy/group';
import incident from '~/copy/incident';
import incidentAlert from '~/copy/incident-alert';
import incidentMessage from '~/copy/incident-message';
import meeting from '~/copy/meeting';
import member from '~/copy/member';
import org from '~/copy/org';
import password from '~/copy/password';
import reminder from '~/copy/reminder';
import settings from '~/copy/settings';
import share from '~/share/share';

export const copy = {
  ...action,
  ...ask,
  ...auth,
  ...board,
  ...content,
  ...group,
  ...meeting,
  ...member,
  ...org,
  ...incidentAlert,
  ...incidentMessage,
  ...incident,
  ...password,
  ...reminder,
  ...settings,
  ...share,

  date: defineCopy({
    default: 'Date',
    'de-DE': 'Datum',
    'es-ES': 'Fecha',
    'it-IT': 'Data',
    'fr-FR': 'Date',
  }),
  pickADate: defineCopy({
    default: 'Pick a date',
    'de-DE': 'Datum auswählen',
    'es-ES': 'Elija una fecha',
    'it-IT': 'Scegli una data',
    'fr-FR': 'Choisir une date',
  }),
  edit: defineCopy({
    default: 'Edit',
    'de-DE': 'Bearbeiten',
    'es-ES': 'Editar',
    'it-IT': 'Modifica',
    'fr-FR': 'Modifier',
  }),
  location: defineCopy({
    default: 'Location',
    'de-DE': 'Ort',
    'es-ES': 'Ubicación',
    'it-IT': 'Posizione',
    'fr-FR': 'Emplacement',
  }),
  archive: defineCopy({
    default: 'Archive',
    'de-DE': 'Archivieren',
    'es-ES': 'Archivar',
    'it-IT': 'Archiviare',
    'fr-FR': 'Archiver',
  }),
  unarchive: defineCopy({
    default: 'Unarchive',
    'de-DE': 'Wiederherstellen',
    'es-ES': 'Desarchivar',
    'it-IT': 'Disarchivia',
    'fr-FR': 'Désarchiver',
  }),
  archived: defineCopy({
    default: 'Archived',
    'de-DE': 'Archiviert',
    'es-ES': 'Archivado',
    'it-IT': 'Archiviato',
    'fr-FR': 'Archivé',
  }),
  areYouSure: defineCopy({
    default: 'Are you sure?',
    'de-DE': 'Bist du sicher?',
    'es-ES': '¿Estás seguro?',
    'it-IT': 'Sei sicuro?',
    'fr-FR': 'Êtes-vous sûr ?',
  }),
  cancel: defineCopy({
    default: 'Cancel',
    'de-DE': 'Abbrechen',
    'es-ES': 'Cancelar',
    'it-IT': 'Annulla',
    'fr-FR': 'Annuler',
  }),
  save: defineCopy({
    default: 'Save',
    'de-DE': 'Speichern',
    'es-ES': 'Guardar',
    'it-IT': 'Salva',
    'fr-FR': 'Sauvegarder',
  }),
  delete: defineCopy({
    default: 'Delete',
    'de-DE': 'Löschen',
    'es-ES': 'Eliminar',
    'it-IT': 'Elimina',
    'fr-FR': 'Supprimer',
  }),
  sent: defineCopy({
    default: 'Sent',
    'de-DE': 'Gesendet',
    'es-ES': 'Enviado',
    'it-IT': 'Inviato',
    'fr-FR': 'Envoyé',
  }),
  sending: defineCopy({
    default: 'Sending',
    'de-DE': 'Senden',
    'es-ES': 'Enviando',
    'it-IT': 'Invio',
    'fr-FR': 'Envoi',
  }),
  status: defineCopy({
    default: 'Status',
    'de-DE': 'Status',
    'es-ES': 'Estado',
    'it-IT': 'Stato',
    'fr-FR': 'Statut',
  }),
  you: defineCopy({
    default: 'You',
    'de-DE': 'Du',
    'es-ES': 'Tú',
    'it-IT': 'Tu',
    'fr-FR': 'Vous',
  }),
  name: defineCopy({
    default: 'Name',
    'de-DE': 'Name',
    'es-ES': 'Nombre',
    'it-IT': 'Nome',
    'fr-FR': 'Nom',
  }),
  phone: defineCopy({
    default: 'Phone',
    'de-DE': 'Telefon',
    'es-ES': 'Teléfono',
    'it-IT': 'Telefono',
    'fr-FR': 'Téléphone',
  }),
  enterPhone: defineCopy({
    default: 'Enter phone number',
    'de-DE': 'Telefonnummer eingeben',
    'es-ES': 'Ingrese número de teléfono',
    'it-IT': 'Inserisci il numero di telefono',
    'fr-FR': 'Entrez le numéro de téléphone',
  }),
  done: defineCopy({
    default: 'Done',
    'de-DE': 'Fertig',
    'es-ES': 'Hecho',
    'it-IT': 'Fatto',
    'fr-FR': 'Terminé',
  }),
  optional: defineCopy({
    default: 'Optional',
    'de-DE': 'Optional',
    'es-ES': 'Opcional',
    'it-IT': 'Opzionale',
    'fr-FR': 'Optionnel',
  }),
  title: defineCopy({
    default: 'Title',
    'de-DE': 'Titel',
    'es-ES': 'Título',
    'it-IT': 'Titolo',
    'fr-FR': 'Titre',
  }),
  copy: defineCopy({
    default: 'Copy',
    'de-DE': 'Kopieren',
    'es-ES': 'Copiar',
    'it-IT': 'Copia',
    'fr-FR': 'Copier',
  }),
  copied: defineCopy({
    default: 'Copied',
    'de-DE': 'Kopiert',
    'es-ES': 'Copiado',
    'it-IT': 'Copiato',
    'fr-FR': 'Copié',
  }),
  audio: defineCopy({
    default: 'Audio',
    'de-DE': 'Audio',
    'es-ES': 'Audio',
    'it-IT': 'Audio',
    'fr-FR': 'Audio',
  }),
  create: defineCopy({
    default: 'Create',
    'de-DE': 'Erstellen',
    'es-ES': 'Crear',
    'it-IT': 'Crea',
    'fr-FR': 'Créer',
  }),
};
