import { create } from 'zustand';
import { combine } from 'zustand/middleware';
import { getApiUrl, initAsk } from '~/api';
import { getOrgId } from '~/org';

const initialState = {
  response: '',
  query: '',
  isLoading: false,
};

export const useAsk = create(
  combine(initialState, (set, get) => ({
    setQuery: (query: string) => {
      set(() => ({ query }));
    },

    ask: async () => {
      const query = get().query;

      if (!query) return;

      set(() => ({ response: '', query: '', isLoading: true }));

      const orgId = getOrgId();

      const { token } = await initAsk({ orgId, query });

      const url = new URL(getApiUrl('/ask'));

      url.searchParams.append('token', token);

      const eventSource = new EventSource(url);

      eventSource.onmessage = (event) => {
        set(() => ({ response: event.data }));
      };

      eventSource.onerror = () => {
        eventSource.close();
        set(() => ({ isLoading: false }));
      };

      return () => {
        eventSource.close();
        set(() => ({ isLoading: false }));
      };
    },
  })),
);
