import { LiveblocksProvider } from '@liveblocks/react/suspense';
import { AnimatePresence } from 'framer-motion';
import { Route, Routes, useLocation } from 'react-router-dom';
import { getRealtimeToken } from '~/api';
import { AnalyticsProvider } from '~/components/analytics';
import { AuthGuard, AuthProvider } from '~/components/auth';
import { DashboardGuard, DashboardNav } from '~/components/dashboard';
import { HomePage } from '~/components/home';
import { MemberInvite } from '~/components/member';
import { NotFound } from '~/components/not-found';
import { Checkout, Plans } from '~/components/plan';
import { QueryProvider } from '~/components/query';
import { Realtime } from '~/components/realtime';
import { Theme } from '~/components/theme';
import { AskPage } from '~/pages/ask';
import { SignInPage, SignOutPage, SignUpPage } from '~/pages/auth';
import {
  BoardPage,
  BoardSettingsPage,
  BoardsPage,
  CreateBoardPage,
  ShareBoardPage,
} from '~/pages/board';
import {
  ArchivedContentsPage,
  ContentPage,
  ContentSettings,
  ContentsPage,
  CreateContentPage,
  ShareContentPage,
} from '~/pages/content';
import { CreateGroupPage, EditGroupPage, GroupsPage } from '~/pages/group';
import {
  CreateIncidentPage,
  EditIncidentPage,
  IncidentPage,
  IncidentsPage,
  ResolvedIncidentsPage,
  SendIncidentAlertPage,
} from '~/pages/incident';
import { IncidentAlertPage, IncidentAlertsPage } from '~/pages/incident-alert';
import {
  CreateIncidentMessagePage,
  EditIncidentMessagePage,
} from '~/pages/incident-message';
import { MeetingsPage, MeetingVideoPage } from '~/pages/meeting';
import {
  EditMemberPage,
  InviteMemberPage,
  MemberPage,
  MembersPage,
} from '~/pages/member';
import {
  CreatePasswordPage,
  EditPasswordPage,
  PasswordsPage,
  SharePasswordPage,
} from '~/pages/password';
import {
  CreateReminderPage,
  EditReminderPage,
  RemindersPage,
  ShareReminderPage,
} from '~/pages/reminder';
import { EditPronounciationsPage, SettingsPage } from '~/pages/settings';

export const App = () => {
  const location = useLocation();

  return (
    <AuthProvider>
      <AnalyticsProvider>
        <AnimatePresence mode="wait">
          <QueryProvider>
            <LiveblocksProvider
              authEndpoint={async (room) => {
                if (!room) return { token: '' };

                try {
                  const { token } = await getRealtimeToken({ room });

                  return {
                    token,
                  };
                } catch (error) {
                  return {
                    token: '',
                  };
                }
              }}
            >
              <Theme defaultTheme="system">
                <Realtime />

                <DashboardNav />

                <Routes location={location} key={location.pathname}>
                  {/* site */}
                  <Route path="/" element={<HomePage />} />

                  {/* auth */}
                  <Route path="/sign-in" element={<SignInPage />} />
                  <Route path="/sign-up" element={<SignUpPage />} />
                  <Route path="/sign-out" element={<SignOutPage />} />

                  <Route path="/" element={<DashboardGuard />}>
                    {/* content */}

                    <Route path="/content" element={<ContentsPage />} />
                    <Route
                      path="/content/new"
                      element={<CreateContentPage />}
                    />
                    <Route
                      path="/content/archive"
                      element={<ArchivedContentsPage />}
                    />
                    <Route
                      path="/content/:contentId"
                      element={<ContentPage />}
                    />
                    <Route
                      path="/content/:contentId/settings"
                      element={<ContentSettings />}
                    />
                    <Route
                      path="/content/:contentId/share"
                      element={<ShareContentPage />}
                    />

                    {/* ask */}
                    <Route path="/ask" element={<AskPage />} />

                    {/* passwords */}
                    <Route path="/passwords" element={<PasswordsPage />} />
                    <Route
                      path="/passwords/new"
                      element={<CreatePasswordPage />}
                    />
                    <Route
                      path="/passwords/:passwordId/edit"
                      element={<EditPasswordPage />}
                    />
                    <Route
                      path="/passwords/:passwordId/share"
                      element={<SharePasswordPage />}
                    />

                    {/* boards */}
                    <Route path="/boards" element={<BoardsPage />} />
                    <Route path="/boards/new" element={<CreateBoardPage />} />
                    <Route path="/boards/:boardId" element={<BoardPage />} />
                    <Route
                      path="/boards/:boardId/settings"
                      element={<BoardSettingsPage />}
                    />
                    <Route
                      path="/boards/:boardId/share"
                      element={<ShareBoardPage />}
                    />

                    {/* groups */}
                    <Route path="/members/groups" element={<GroupsPage />} />
                    <Route
                      path="/members/groups/new"
                      element={<CreateGroupPage />}
                    />
                    <Route
                      path="/members/groups/:groupId/edit"
                      element={<EditGroupPage />}
                    />

                    {/* members */}
                    <Route path="/members" element={<MembersPage />} />
                    <Route
                      path="/members/invite"
                      element={<InviteMemberPage />}
                    />
                    <Route path="/members/:memberId" element={<MemberPage />} />
                    <Route
                      path="/members/:memberId/edit"
                      element={<EditMemberPage />}
                    />

                    {/* meeting */}
                    <Route path="/meetings" element={<MeetingsPage />} />
                    <Route
                      path="/meetings/:meetingId"
                      element={<MeetingVideoPage />}
                    />

                    {/* incidents */}
                    <Route path="/incidents" element={<IncidentsPage />} />
                    <Route
                      path="/incidents/new"
                      element={<CreateIncidentPage />}
                    />
                    <Route
                      path="/incidents/resolved"
                      element={<ResolvedIncidentsPage />}
                    />
                    <Route
                      path="/incidents/:incidentId"
                      element={<IncidentPage />}
                    />
                    <Route
                      path="/incidents/:incidentId/edit"
                      element={<EditIncidentPage />}
                    />
                    <Route
                      path="/incidents/:incidentId/send-alert"
                      element={<SendIncidentAlertPage />}
                    />
                    <Route
                      path="/incidents/:incidentId/messages/new"
                      element={<CreateIncidentMessagePage />}
                    />
                    <Route
                      path="/incidents/:incidentId/messages/:incidentMessageId/edit"
                      element={<EditIncidentMessagePage />}
                    />
                    <Route
                      path="/incidents/:incidentId/alerts"
                      element={<IncidentAlertsPage />}
                    />
                    <Route
                      path="/incidents/:incidentId/alerts/:incidentAlertId"
                      element={<IncidentAlertPage />}
                    />

                    {/* reminders */}
                    <Route path="/reminders" element={<RemindersPage />} />
                    <Route
                      path="/reminders/new"
                      element={<CreateReminderPage />}
                    />
                    <Route
                      path="/reminders/:reminderId/edit"
                      element={<EditReminderPage />}
                    />
                    <Route
                      path="/reminders/:reminderId/share"
                      element={<ShareReminderPage />}
                    />

                    {/* settings */}
                    <Route path="/settings" element={<SettingsPage />} />
                    <Route
                      path="/settings/pronunciations"
                      element={<EditPronounciationsPage />}
                    />
                  </Route>

                  <Route path="/" element={<AuthGuard />}>
                    {/* join */}
                    <Route path="/join/:code" element={<MemberInvite />} />

                    {/* plans */}
                    <Route
                      path="/plans"
                      element={
                        <main className="px-6 py-28 md:px-20 md:py-40">
                          <Plans />
                        </main>
                      }
                    />
                    <Route path="/plans/pay/checkout" element={<Checkout />} />
                  </Route>

                  {/* not found */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Theme>
            </LiveblocksProvider>
          </QueryProvider>
        </AnimatePresence>
      </AnalyticsProvider>
    </AuthProvider>
  );
};
