import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { inviteMember } from '~/api';
import { BasicButton, Input, Separator } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';
import { useOrg } from '~/hooks/org';
import { sentry } from '~/sentry';

export const InviteMemberPage = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const copy = useCopy();
  const { isOwner } = useOrg();

  const createMember = useMutation({
    mutationFn: async ({ email }: { email: string }) => {
      await inviteMember({ email });
    },
    onSuccess: () => {
      analytics.track('member.invite');

      navigate('/members');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  useEffect(() => {
    if (!isOwner()) {
      navigate('/members');
    }
  }, [isOwner, navigate]);

  if (!isOwner()) return null;

  return (
    <DashboardPage headerTitle={copy.get('newMember')} back="/members">
      <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
        <form
          className="space-y-6 md:space-y-10"
          onSubmit={(event) => {
            event.preventDefault();

            if (!email) return;

            createMember.mutate({
              email: email.trim(),
            });
          }}
        >
          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('enterTheirEmail')}
            </div>

            <Separator />

            <div className="p-6">
              <Input
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                placeholder="Email"
                type="email"
              />
            </div>
          </div>

          <BasicButton
            className="w-full"
            type="submit"
            disabled={createMember.isPending || !email}
          >
            {createMember.isPending ? (
              <Loader2 className="h-6 w-6 animate-spin" />
            ) : (
              copy.get('inviteMember')
            )}
          </BasicButton>
        </form>
      </div>
    </DashboardPage>
  );
};
