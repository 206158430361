import { useEffect, useState } from 'react';
import { getPassword } from '~/api';
import { Redirect } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { Share } from '~/components/share';
import { Password } from '~/db';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { useShare } from '~/hooks/share';
import { getOrgId } from '~/org';

export const SharePasswordPage = () => {
  const { passwordId } = useParams<{ passwordId: string }>();
  const [password, setPassword] = useState<Password | null>(null);

  useEffect(() => {
    const fn = async () => {
      const orgId = getOrgId();
      const { password } = await getPassword({ orgId, passwordId });

      setPassword(password);
    };

    fn();
  }, [passwordId]);

  if (!password) return null;

  return <SharePassword password={password} />;
};

export const SharePassword = ({ password }: { password: Password }) => {
  const copy = useCopy();
  const { canShare } = useShare(password);

  if (!canShare) return <Redirect path="/passwords" />;

  return (
    <DashboardPage headerTitle={copy.get('sharePassword')} back="/passwords">
      <Share
        resourceType="password"
        resourceId={password.id}
        saveSuccessPath="/passwords"
      />
    </DashboardPage>
  );
};
