import { useQuery } from '@tanstack/react-query';
import { listContents } from '~/api';
import { ActionButton, Separator } from '~/components';
import { Contents } from '~/components/content';
import { DashboardPage } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';

export const ContentsPage = () => {
  const copy = useCopy();

  const { data } = useQuery({
    queryKey: ['content'],
    queryFn: async () => {
      const { contents, sharedContents } = await listContents({
        filter: {
          isArchived: false,
        },
      });

      return { contents, sharedContents };
    },
  });

  if (!data) return null;

  const contents = data.contents;
  const sharedContents = data.sharedContents;
  const hasNoContents = contents.length === 0 && sharedContents.length === 0;

  return (
    <DashboardPage title={copy.get('contents')}>
      <div className="mx-auto max-w-4xl pb-12">
        <h1 className="text-lg font-semibold lg:text-2xl">
          {copy.get('contents')}
        </h1>
      </div>

      <div className="space-y-10 md:space-y-12">
        {hasNoContents && (
          <div className="mx-auto max-w-4xl text-balance rounded-lg border px-6 py-20 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noContent')}
          </div>
        )}

        <Contents contents={contents} />

        {sharedContents.length > 0 && (
          <>
            {contents.length > 0 && (
              <div className="md:py-10">
                <Separator />
              </div>
            )}

            <div className="mx-auto max-w-4xl space-y-6 md:space-y-8">
              <h2 className="text-lg md:text-xl">
                {copy.get('sharedWithYou')}
              </h2>

              <Contents contents={sharedContents} />
            </div>
          </>
        )}
      </div>

      <ActionButton href="/content/new" />
    </DashboardPage>
  );
};
