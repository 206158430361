import { useEffect } from 'react';
import { DashboardPageHeader } from '~/components/dashboard';
import { cn } from '~/style';

export const DashboardPage = ({
  title,
  headerTitle,
  back,
  headerOptions,
  mainClassName,
  children,
}: {
  title?: string;
  headerTitle?: string;
  back?: string;
  headerOptions?: React.ReactNode;
  mainClassName?: string;
  children: React.ReactNode;
}) => {
  useEffect(() => {
    const documentTitle = title || headerTitle;

    document.title = documentTitle
      ? `${documentTitle} | Contential`
      : 'Contential';
  }, [title, headerTitle]);

  return (
    <div className="min-h-screen pt-16 md:pt-20 lg:pt-24">
      {(headerTitle || headerOptions) && (
        <DashboardPageHeader
          title={headerTitle}
          options={headerOptions}
          back={back}
        />
      )}

      <main
        className={cn(
          'min-h-screen px-8 pb-32 pt-6 md:pb-40 md:pt-8 lg:pb-48 lg:pt-16',
          mainClassName,
        )}
      >
        {children}
      </main>
    </div>
  );
};
