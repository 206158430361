import { useMutation, useQuery } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { getMember, removeMember } from '~/api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  Badge,
  BasicButton,
  Link,
  OriginalButton,
} from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { useParams } from '~/hooks';
import { useUser } from '~/hooks/auth';
import { useCopy } from '~/hooks/copy';
import { useOrg } from '~/hooks/org';
import { sentry } from '~/sentry';

export const MemberPage = () => {
  const { memberId } = useParams<{ memberId: string }>();
  const { isOwner } = useOrg();
  const { user } = useUser();
  const navigate = useNavigate();
  const copy = useCopy();

  const { data: member } = useQuery({
    queryKey: ['members', memberId],
    queryFn: async () => {
      const { member } = await getMember({ memberId });

      return member;
    },
  });

  const remove = useMutation({
    mutationFn: async () => {
      await removeMember({
        memberId,
      });
    },
    onSuccess: () => {
      analytics.track('member.delete');

      navigate('/members');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  if (!user || !member) return null;

  const isCurrentUser = member.id === user.id;

  return (
    <DashboardPage title={member.name} back="/members">
      <div className="mx-auto grid w-full max-w-xl gap-6">
        <div className="w-full space-y-2 rounded-lg border p-6">
          <div className="flex w-full items-center gap-4">
            <h1 className="text-2xl font-bold">{member.name}</h1>

            <div>
              {isCurrentUser && (
                <Badge variant="secondary">{copy.get('you')}</Badge>
              )}
            </div>
          </div>

          <div className="text-muted-foreground">{member.email}</div>

          {member.phone && (
            <div className="text-muted-foreground">{member.phone}</div>
          )}
        </div>

        {isOwner() ? (
          <div className="flex flex-col gap-6 rounded-lg border p-6 text-center md:flex-row">
            {isCurrentUser && (
              <Link href={`/members/${member.id}/edit`}>
                <BasicButton variant="outline" size="sm">
                  {copy.get('edit')}
                </BasicButton>
              </Link>
            )}

            {!isCurrentUser && (
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <BasicButton
                    variant="outline"
                    size="sm"
                    disabled={remove.isPending}
                  >
                    {remove.isPending ? (
                      <Loader2 className="h-6 w-6 animate-spin" />
                    ) : (
                      copy.get('removeMember')
                    )}
                  </BasicButton>
                </AlertDialogTrigger>

                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      {copy.get('areYouSure')}
                    </AlertDialogTitle>

                    <AlertDialogDescription className="pb-6">
                      {copy.get('deleteMemberMessage')}
                    </AlertDialogDescription>
                  </AlertDialogHeader>

                  <AlertDialogFooter>
                    <AlertDialogCancel>{copy.get('cancel')}</AlertDialogCancel>

                    <AlertDialogAction asChild>
                      <OriginalButton
                        variant="destructive"
                        onClick={() => remove.mutate()}
                      >
                        {copy.get('removeMember')}
                      </OriginalButton>
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            )}
          </div>
        ) : (
          isCurrentUser && (
            <div className="flex flex-col gap-6 rounded-lg border p-6 text-center md:flex-row">
              <Link href={`/members/${member.id}/edit`}>
                <BasicButton variant="outline" size="sm">
                  {copy.get('edit')}
                </BasicButton>
              </Link>
            </div>
          )
        )}
      </div>
    </DashboardPage>
  );
};
