import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { deleteGroup, getGroup, updateGroup } from '~/api';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  BasicButton,
  Form,
  FormInput,
  FormSubmitButton,
  OriginalButton,
} from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { MembersSelect } from '~/components/member';
import { GroupExpanded } from '~/db';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { useMembers, useSelectMembers } from '~/hooks/member';
import { sentry } from '~/sentry';

export const EditGroupPage = () => {
  const { groupId } = useParams<{ groupId: string }>();
  const [group, setGroup] = useState<GroupExpanded | null>(null);

  useEffect(() => {
    const fn = async () => {
      const { group } = await getGroup({ groupId });

      setGroup(group);
    };

    fn();
  }, [groupId]);

  if (!group) return null;

  return <EditGroup group={group} />;
};

export const EditGroup = ({ group }: { group: GroupExpanded }) => {
  const [name, setName] = useState(group.name);
  const { memberIds, setMemberId } = useSelectMembers(group.groupMembers);
  const copy = useCopy();
  const navigate = useNavigate();
  const { members } = useMembers({ omitCurrentUser: true });

  const update = useMutation({
    mutationFn: async () => {
      await updateGroup({
        groupId: group.id,
        updateData: {
          name,
        },
        memberIds,
      });
    },
    onSuccess: async () => {
      analytics.track('group.update');

      navigate('/members/groups');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const submitUpdate = () => {
    if (!name) return;

    update.mutate();
  };

  return (
    <DashboardPage
      headerTitle={copy.get('editGroup')}
      back="/members/groups"
      headerOptions={
        <>
          <DeleteGroupButton group={group} />
        </>
      }
    >
      <div className="mx-auto w-full max-w-2xl space-y-6 md:space-y-10 lg:space-y-14">
        <Form onSubmit={submitUpdate}>
          <FormInput
            value={name}
            onChange={setName}
            label="groupName"
            placeholder="name"
          />
        </Form>

        {members && (
          <div className="space-y-4">
            <div>{copy.get('members')}</div>

            <MembersSelect
              members={members}
              memberIds={memberIds}
              onMemberSelect={setMemberId}
            />
          </div>
        )}

        <FormSubmitButton
          isLoading={update.isPending}
          label="save"
          onClick={submitUpdate}
        />
      </div>
    </DashboardPage>
  );
};

const DeleteGroupButton = ({ group }: { group: GroupExpanded }) => {
  const copy = useCopy();
  const navigate = useNavigate();
  const remove = useMutation({
    mutationFn: async () => {
      await deleteGroup({
        groupId: group.id,
      });
    },
    onSuccess: async () => {
      analytics.track('group.delete');

      navigate('/members/groups');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <BasicButton variant="outline" size="sm" disabled={remove.isPending}>
          {remove.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            <svg
              className="size-5"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
              />
            </svg>
          )}
        </BasicButton>
      </AlertDialogTrigger>

      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{copy.get('areYouSure')}</AlertDialogTitle>

          <AlertDialogDescription className="pb-6">
            {copy.get('deleteGroup')}
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>

          <AlertDialogAction asChild>
            <OriginalButton
              onClick={() => {
                remove.mutate();
              }}
            >
              {copy.get('delete')}
            </OriginalButton>
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
