import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateOrgPronunciations } from '~/api';
import { BasicButton, Separator, Textarea } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { Org } from '~/db';
import { useCopy } from '~/hooks/copy';
import { useOrg } from '~/hooks/org';
import { sentry } from '~/sentry';

export const EditPronounciationsPage = () => {
  const { org } = useOrg();

  if (!org) return null;

  return <EditPronounciations org={org} />;
};

export const EditPronounciations = ({ org }: { org: Org }) => {
  const copy = useCopy();
  const [pronunciations, setPronounciations] = useState(
    org.pronunciations || '',
  );
  const navigate = useNavigate();
  const { isOwner } = useOrg();

  const update = useMutation({
    mutationFn: async ({ pronunciations }: { pronunciations: string }) => {
      await updateOrgPronunciations({
        pronunciations,
      });
    },
    onSuccess: async () => {
      navigate('/settings');
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  if (!isOwner()) return null;

  return (
    <DashboardPage
      headerTitle={copy.get('editPronunciations')}
      back={'/settings'}
    >
      <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
        <form
          className="space-y-6 md:space-y-10"
          onSubmit={(event) => {
            event.preventDefault();

            update.mutate({
              pronunciations,
            });
          }}
        >
          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('pronunciations')}
            </div>

            <Separator />

            <div className="p-6">
              <Textarea
                value={pronunciations}
                onChange={(event) => setPronounciations(event.target.value)}
                rows={10}
              />
            </div>
          </div>

          <BasicButton
            className="w-full"
            type="submit"
            disabled={update.isPending}
          >
            {update.isPending ? (
              <Loader2 className="h-6 w-6 animate-spin" />
            ) : (
              copy.get('save')
            )}
          </BasicButton>
        </form>

        <input
          id="hidden"
          className="pointer-events-none fixed left-full top-full -z-50 h-0 w-0 opacity-0"
          type="text"
        />
      </div>
    </DashboardPage>
  );
};
