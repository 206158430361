import { useMutation } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { CalendarIcon, Loader2 } from 'lucide-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { generateIncidentSummary, updateIncident } from '~/api';
import {
  BasicButton,
  Calendar,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Separator,
  Textarea,
} from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { IncidentProvider } from '~/components/incident';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { useIncident } from '~/hooks/incident';
import { useOrg } from '~/hooks/org';
import { invalidateQueries } from '~/query';
import { sentry } from '~/sentry';
import { cn } from '~/style';
import {
  convertUnixToDate,
  dateToCurrentUnix,
  formatDate,
  getUnix,
} from '~/utils';

export const EditIncidentPage = () => {
  const { incidentId } = useParams<{ incidentId: string }>();

  return (
    <IncidentProvider incidentId={incidentId}>
      <EditIncident />
    </IncidentProvider>
  );
};

export const EditIncident = () => {
  const incident = useIncident();
  const copy = useCopy();
  const [date, setDate] = useState<Date>(convertUnixToDate(incident.date));
  const [title, setTitle] = useState(incident.title || '');
  const [location, setLocation] = useState(incident.location || '');
  const [description, setDescription] = useState(incident.description);
  const navigate = useNavigate();
  const { isOwner } = useOrg();

  useEffect(() => {
    if (!isOwner()) {
      navigate(`/incidents/${incident.id}`);
    }
  }, [incident.id, isOwner, navigate]);

  const update = useMutation({
    mutationFn: async (input: {
      title: string;
      description: string;
      location?: string;
      date: number;
    }) => {
      await updateIncident({
        incidentId: incident.id,
        updateData: {
          title: input.title,
          description: input.description,
          location: input.location,
          date: input.date,
        },
      });

      await generateIncidentSummary({
        orgId: incident.orgId,
        incidentId: incident.id,
      });
    },
    onSuccess: async () => {
      analytics.track('incident.update');

      invalidateQueries(['incidents']);
      invalidateQueries(['incident', incident.id]);

      navigate(`/incidents/${incident.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  if (!isOwner()) return null;

  return (
    <DashboardPage
      headerTitle={copy.get('editIncident')}
      back={`/incidents/${incident.id}`}
    >
      <div className="mx-auto w-full max-w-2xl space-y-10 md:space-y-14">
        <form
          className="space-y-6 md:space-y-10"
          onSubmit={(event) => {
            event.preventDefault();

            const currentDate = formatDate(incident.date);
            const newDate = formatDate(getUnix(date));
            const dateHasChanged = currentDate !== newDate;

            update.mutate({
              title,
              description,
              location,
              date: dateHasChanged ? dateToCurrentUnix(date) : incident.date,
            });
          }}
        >
          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('incidentTitle')}
            </div>

            <Separator />

            <div className="p-6">
              <Input
                value={title}
                placeholder={copy.get('title')}
                onChange={(event) => setTitle(event.target.value)}
              />
            </div>
          </div>

          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">{copy.get('date')}</div>

            <Separator />

            <div className="p-6">
              <Popover>
                <PopoverTrigger asChild>
                  <BasicButton
                    variant={'outline'}
                    className={cn(
                      'w-full justify-start text-left font-normal',
                      !date && 'text-muted-foreground',
                    )}
                  >
                    <CalendarIcon className="mr-2 h-4 w-4" />

                    {date ? (
                      dayjs(date).format('D MMM YYYY')
                    ) : (
                      <span>{copy.get('pickADate')}</span>
                    )}
                  </BasicButton>
                </PopoverTrigger>

                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={date}
                    onSelect={(event) => {
                      if (!(event instanceof Date)) return;

                      setDate(event);
                      document.getElementById('hidden')?.focus();
                    }}
                  />
                </PopoverContent>
              </Popover>
            </div>
          </div>

          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('description')}
            </div>

            <Separator />

            <div className="p-6">
              <Textarea
                value={description}
                onChange={(event) => setDescription(event.target.value)}
                rows={20}
              />
            </div>
          </div>

          <div className="rounded-lg border">
            <div className="bg-muted/50 px-6 py-4">
              {copy.get('location')}{' '}
              <span className="lowercase text-muted-foreground">
                ({copy.get('optional')})
              </span>
            </div>

            <Separator />

            <div className="p-6">
              <Textarea
                value={location}
                onChange={(event) => setLocation(event.target.value)}
                rows={4}
              />
            </div>
          </div>
          <BasicButton
            className="w-full"
            type="submit"
            disabled={update.isPending || !description}
          >
            {update.isPending ? (
              <Loader2 className="h-6 w-6 animate-spin" />
            ) : (
              copy.get('save')
            )}
          </BasicButton>
        </form>

        <input
          id="hidden"
          className="pointer-events-none fixed left-full top-full -z-50 h-0 w-0 opacity-0"
          type="text"
        />
      </div>
    </DashboardPage>
  );
};
