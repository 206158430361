import { useQuery } from '@tanstack/react-query';
import { listMembers } from '~/api';
import { ActionButton, Badge, Link, Separator } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { MemberInvites } from '~/components/member';
import { useUser } from '~/hooks/auth';
import { useCopy } from '~/hooks/copy';
import { useOrg } from '~/hooks/org';

export const MembersPage = () => {
  const { user } = useUser();
  const copy = useCopy();
  const { isOwner } = useOrg();

  const { data: members } = useQuery({
    queryKey: ['members'],
    queryFn: async () => {
      const { members } = await listMembers({});

      return members;
    },
  });

  if (!members) return null;

  return (
    <DashboardPage title={copy.get('members')}>
      <div className="mx-auto w-full max-w-4xl space-y-6">
        <h1 className="pb-6 text-lg font-semibold lg:text-2xl">
          {copy.get('members')}
        </h1>

        {members.length > 0 && (
          <div className="mx-auto w-full max-w-4xl overflow-hidden rounded-lg border">
            {members.map((member, memberIndex) => {
              const isCurrentUser = member.memberId === user.id;

              return (
                <div className="hover:bg-muted/40" key={member.member.id}>
                  {memberIndex !== 0 && <Separator />}

                  <Link href={`/members/${member.member.id}`}>
                    <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:gap-14 lg:py-6 xl:py-8">
                      <>
                        <div className="flex items-center gap-4">
                          <div className="font-semibold">
                            {member.member.name}
                          </div>

                          {isCurrentUser && (
                            <Badge variant="secondary">{copy.get('you')}</Badge>
                          )}
                        </div>

                        <div className="text-muted-foreground">
                          {member.member.email}
                        </div>

                        <div className="text-muted-foreground lg:ml-auto">
                          {member.member.phone}
                        </div>
                      </>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <MemberInvites showSeparator />

      {isOwner() && <ActionButton href="/members/invite" />}
    </DashboardPage>
  );
};
