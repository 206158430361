import { Query, Response } from '~/components/ask';
import { DashboardPage } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';

export const AskPage = () => {
  const copy = useCopy();

  return (
    <DashboardPage headerTitle={copy.get('ask')}>
      <Query />

      <Response />
    </DashboardPage>
  );
};
