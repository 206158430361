import { BasicButton, Link } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { getOrgId } from '~/org';

export const MeetingsPage = () => {
  // TODO
  const meetingId = getOrgId();

  return (
    <DashboardPage title="Meeting">
      <div className="mx-auto max-w-4xl space-y-6">
        <h1 className="pb-6 text-lg font-semibold lg:text-2xl">Meeting</h1>

        <div className="rounded-lg border p-16 text-center">
          <Link href={`/meetings/${meetingId}`}>
            <BasicButton>Join Meeting</BasicButton>
          </Link>
        </div>
      </div>
    </DashboardPage>
  );
};
