import { z } from 'zod';
import * as db from './schema';

// org member
export const OrgMemberExpandedSchema = db.OrgMemberSchema.extend({
  member: db.UserSchema,
});

export type OrgMemberExpanded = z.infer<typeof OrgMemberExpandedSchema>;

// member incident alert
export const MemberIncidentAlertExpandedSchema =
  db.MemberIncidentAlertSchema.extend({
    member: db.UserSchema,
  });

export type MemberIncidentAlertExpanded = z.infer<
  typeof MemberIncidentAlertExpandedSchema
>;

// incident message
export const IncidentMessageExpandedSchema = db.IncidentMessageSchema.extend({
  user: db.UserSchema,
});

export type IncidentMessageExpanded = z.infer<
  typeof IncidentMessageExpandedSchema
>;

// share
export const ResourceType = z.enum([
  'content',
  'password',
  'board',
  'incident',
  'meeting',
  'reminder',
]);

export type ResourceType = z.infer<typeof ResourceType>;

// share member
export const ShareMemberExpandedSchema = db.ShareMemberSchema.extend({
  member: db.UserSchema,
});

export type ShareMemberExpanded = z.infer<typeof ShareMemberExpandedSchema>;

// share group
export const ShareGroupExpandedSchema = db.ShareGroupSchema.extend({
  group: db.GroupSchema,
});

export type ShareGroupExpanded = z.infer<typeof ShareGroupExpandedSchema>;

// group member
export const GroupMemberExpandedSchema = db.GroupMemberSchema.extend({
  member: db.UserSchema,
});

export type GroupMemberExpanded = z.infer<typeof GroupMemberExpandedSchema>;

// group
export const GroupExpandedSchema = db.GroupSchema.extend({
  groupMembers: z.array(GroupMemberExpandedSchema),
});

export type GroupExpanded = z.infer<typeof GroupExpandedSchema>;

// board stages input
export const BoardStagesInputSchema = z.array(
  z.union([z.string(), z.number()]),
);

export type BoardStagesInput = z.infer<typeof BoardStagesInputSchema>;

// board stage items
export const BoardStageItemsSchema = z.array(
  z.object({
    id: z.string(),
    title: z.string(),
  }),
);

export type BoardStageItems = z.infer<typeof BoardStageItemsSchema>;

// board items input
export const BoardItemsInputSchema = z.record(
  z.array(z.union([z.string(), z.number()])),
);

export type BoardItemsInput = z.infer<typeof BoardItemsInputSchema>;

// board stage
export const BoardStageExpandedSchema = db.BoardStageSchema.extend({
  boardItems: z.array(db.BoardItemSchema),
});

export type BoardStageExpanded = z.infer<typeof BoardStageExpandedSchema>;
