'use client';
import { useMutation } from '@tanstack/react-query';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { analytics } from '~/analytics';
import { createOrg } from '~/api';
import { BasicButton, Input } from '~/components';
import { useCopy } from '~/hooks/copy';
import { useOrg } from '~/hooks/org';
import { sentry } from '~/sentry';

export const CreateOrg = () => {
  const [name, setName] = useState('');
  const { setOrg, loadFirstOrg } = useOrg();
  const copy = useCopy();

  const mutation = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const { org } = await createOrg({ name });

      await loadFirstOrg();

      return org;
    },
    onSuccess: (org) => {
      analytics.track('org.create');
      setOrg(org);
    },
    onError: (error: any) => {
      sentry.captureError(error);
    },
  });

  return (
    <div className="mx-auto w-full max-w-2xl space-y-10 px-6 py-16 md:mt-20 md:space-y-14">
      <h1 className="text-center text-2xl font-bold md:text-3xl">
        {copy.get('createOrg')}
      </h1>

      <form
        className="space-y-6 md:space-y-10"
        onSubmit={(event) => {
          event.preventDefault();

          if (!name) return;

          mutation.mutate({ name });
        }}
      >
        <Input
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={copy.get('name')}
        />

        <BasicButton
          className="w-full"
          type="submit"
          disabled={mutation.isPending || !name}
        >
          {mutation.isPending ? (
            <Loader2 className="h-6 w-6 animate-spin" />
          ) : (
            copy.get('create')
          )}
        </BasicButton>
      </form>
    </div>
  );
};
