import { useEffect } from 'react';
import { CreateOrg } from '~/components/org';
import { useOrg } from '~/hooks/org';

export const OrgGuard = ({ children }: { children: React.ReactNode }) => {
  const { isReady, hasOrg, loadFirstOrg, setIsReady } = useOrg();

  useEffect(() => {
    const fn = async () => {
      await loadFirstOrg();

      setIsReady(true);
    };

    fn();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isReady) return null;

  if (!hasOrg()) {
    return (
      <>
        <CreateOrg />
      </>
    );
  }

  return <>{children}</>;
};
