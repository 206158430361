import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { getBoard } from '~/api';
import { Board, BoardSettingsButton } from '~/components/board';
import { DashboardPage } from '~/components/dashboard';
import { useParams } from '~/hooks';

export const BoardPage = () => {
  const { boardId } = useParams<{ boardId: string }>();
  const navigate = useNavigate();

  const { data: board } = useQuery({
    queryKey: ['board', boardId],
    queryFn: async () => {
      const { board } = await getBoard({
        boardId,
      });

      if (!board) {
        navigate('/boards');
      }

      return board;
    },
  });

  if (!board) return null;

  return (
    <DashboardPage
      headerTitle={board.name}
      back="/boards"
      headerOptions={
        <>
          <BoardSettingsButton href={`/boards/${board.id}/settings`} />
        </>
      }
      mainClassName="px-0"
    >
      <Board board={board} />
    </DashboardPage>
  );
};
