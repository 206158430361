import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Button, Separator, Switch } from '~/components';
import { useCopy } from '~/hooks/copy';

export const GroupsSelect = <G extends { id: string; name: string }>({
  groups,
  groupIds,
  onGroupSelect,
  children,
  noGroups,
}: {
  groups: G[];
  groupIds: string[];
  onGroupSelect: (groupId: string) => void;
  children?: React.ReactNode;
  noGroups?: React.ReactNode;
}) => {
  const copy = useCopy();
  const navigate = useNavigate();

  if (!groups.length)
    return (
      noGroups || (
        <div className="mx-auto w-full max-w-4xl space-y-8 rounded-xl border p-8 text-center md:space-y-10">
          <h2 className="text-lg md:text-xl lg:text-2xl">
            {copy.get('addGroup')}
          </h2>

          <div>
            <Button
              className="w-40 md:w-52"
              label="addGroup"
              variant="outline"
              onMouseUp={() => navigate('/members/groups/new')}
            />
          </div>
        </div>
      )
    );

  return (
    <>
      <div className="overflow-hidden rounded-lg border">
        {groups.map((group, groupIndex) => {
          const isSelected = groupIds.includes(group.id);

          return (
            <div
              className="cursor-pointer select-none hover:bg-muted/40"
              key={group.id}
              onClick={() => onGroupSelect(group.id)}
            >
              {groupIndex !== 0 && <Separator />}

              <div className="flex flex-col gap-4 px-8 py-6 lg:flex-row lg:items-center lg:gap-14 lg:py-6 xl:py-8">
                <Switch checked={isSelected} />

                <motion.div
                  className="flex flex-col gap-1 lg:flex-row lg:items-center lg:gap-14"
                  initial={{ opacity: isSelected ? 1 : 0.5 }}
                  animate={{
                    opacity: isSelected ? 1 : 0.5,
                  }}
                >
                  <div className="flex items-center gap-4">
                    <div className="font-semibold">{group.name}</div>
                  </div>
                </motion.div>
              </div>
            </div>
          );
        })}
      </div>

      {children}
    </>
  );
};
