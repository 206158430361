import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { analytics } from '~/analytics';
import { createBoard } from '~/api';
import { Form, FormInput, FormSubmitButton } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { useCopy } from '~/hooks/copy';
import { sentry } from '~/sentry';

export const CreateBoardPage = () => {
  const [name, setName] = useState('');
  const navigate = useNavigate();
  const copy = useCopy();

  const create = useMutation({
    mutationFn: async () => {
      const { board } = await createBoard({
        name,
        createDefaults: true,
      });

      return board;
    },
    onSuccess: async (board) => {
      analytics.track('board.create');

      navigate(`/boards/${board.id}`);
    },
    onError: (error) => {
      sentry.captureError(error);
    },
  });

  const submitCreate = () => {
    if (!name) return;

    create.mutate();
  };

  return (
    <DashboardPage headerTitle={copy.get('newBoard')} back="/boards">
      <div className="mx-auto w-full max-w-2xl space-y-6 md:space-y-10 lg:space-y-14">
        <Form onSubmit={submitCreate}>
          <FormInput
            value={name}
            onChange={setName}
            label="boardName"
            placeholder="name"
          />

          <FormSubmitButton isLoading={create.isPending} label="createBoard" />
        </Form>
      </div>
    </DashboardPage>
  );
};
