import { Textarea } from '~/components';
import { CopyKey } from '~/copy';
import { useCopy } from '~/hooks/copy';

export const FormTextarea = ({
  value,
  label,
  placeholder,
  rows,
  optional,
  onChange,
}: {
  value: string;
  label?: CopyKey;
  placeholder?: CopyKey;
  rows?: number;
  optional?: boolean;
  onChange: (value: string) => void;
}) => {
  const copy = useCopy();

  return (
    <div className="space-y-4">
      {label && (
        <div>
          <span>{copy.get(label)}</span>
          {optional && (
            <span className="lowercase text-muted-foreground">
              {' '}
              ({copy.get('optional')})
            </span>
          )}
        </div>
      )}

      <Textarea
        value={value}
        onChange={(event) => onChange(event.target.value)}
        placeholder={placeholder && copy.get(placeholder)}
        rows={rows || 5}
      />
    </div>
  );
};
