import { useQuery } from '@tanstack/react-query';
import { listPasswords } from '~/api';
import {
  ActionButton,
  CopyPassword,
  EditButton,
  Separator,
} from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { ShareButton } from '~/components/share';
import { Password } from '~/db';
import { useCopy } from '~/hooks/copy';
import { decodePassword } from '~/password';
import { formatText } from '~/utils';

export const PasswordsPage = () => {
  const copy = useCopy();

  const { data: passwords } = useQuery({
    queryKey: ['passwords'],
    queryFn: async () => {
      const { passwords } = await listPasswords({});

      return passwords;
    },
  });

  if (!passwords) return null;

  return (
    <DashboardPage title={copy.get('passwords')}>
      <div className="mx-auto w-full max-w-4xl space-y-6">
        <h1 className="pb-6 text-lg font-semibold lg:text-2xl">
          {copy.get('passwords')}
        </h1>

        {passwords.length > 0 ? (
          <div className="mx-auto w-full max-w-4xl space-y-6">
            {passwords.map((password) => {
              return <PasswordCard key={password.id} password={password} />;
            })}
          </div>
        ) : (
          <div className="text-balance rounded-lg border px-6 py-20 text-center text-lg font-medium text-muted-foreground">
            {copy.get('noPasswords')}
          </div>
        )}
      </div>

      <ActionButton href="/passwords/new" />
    </DashboardPage>
  );
};

const PasswordCard = ({ password }: { password: Password }) => {
  const copy = useCopy();

  return (
    <div className="rounded-lg border" key={password.id}>
      <div className="flex flex-col gap-2 px-8 py-6 lg:flex-row lg:items-center lg:gap-14">
        <div className="text-lg font-semibold md:text-xl">{password.name}</div>

        <div className="ml-auto flex gap-2 md:gap-3">
          <ShareButton
            resource={password}
            href={`/passwords/${password.id}/share`}
          />
          <EditButton href={`/passwords/${password.id}/edit`} />
        </div>
      </div>

      {password.description && (
        <>
          <Separator />

          <div
            className="px-8 py-4 leading-relaxed text-muted-foreground"
            dangerouslySetInnerHTML={{
              __html: formatText(password.description),
            }}
          />
        </>
      )}

      <Separator />

      <div className="space-y-3 p-8">
        <div className="text-sm text-muted-foreground">
          {copy.get('password')}
        </div>

        <CopyPassword text={decodePassword(password.password)} />
      </div>
    </div>
  );
};
