import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  BoardItemDetail,
  BoardProvider,
  BoardStages,
} from '~/components/board';
import { Board as BoardType } from '~/db';

export const Board = ({ board }: { board: BoardType }) => {
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
  );

  return (
    <DndContext sensors={sensors}>
      <BoardProvider boardId={board.id}>
        <div className="hide-scrollbar min-h-96 overflow-x-auto lg:px-10">
          <BoardStages />
        </div>

        <BoardItemDetail />
      </BoardProvider>
    </DndContext>
  );
};
