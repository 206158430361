import { useEffect, useState } from 'react';
import { getBoard } from '~/api';
import { Redirect } from '~/components';
import { DashboardPage } from '~/components/dashboard';
import { Share } from '~/components/share';
import { Board } from '~/db';
import { useParams } from '~/hooks';
import { useCopy } from '~/hooks/copy';
import { useShare } from '~/hooks/share';

export const ShareBoardPage = () => {
  const { boardId } = useParams<{ boardId: string }>();
  const [board, setBoard] = useState<Board | null>(null);

  useEffect(() => {
    const fn = async () => {
      const { board } = await getBoard({ boardId });

      setBoard(board);
    };

    fn();
  }, [boardId]);

  if (!board) return null;

  return <ShareBoard board={board} />;
};

export const ShareBoard = ({ board }: { board: Board }) => {
  const copy = useCopy();
  const { canShare } = useShare(board);

  if (!canShare) return <Redirect path="/boards" />;

  return (
    <DashboardPage headerTitle={copy.get('shareBoard')} back="/boards">
      <Share
        resourceType="board"
        resourceId={board.id}
        saveSuccessPath={`/boards/${board.id}`}
      />
    </DashboardPage>
  );
};
